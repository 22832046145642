import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box
} from "@mui/material";
import { IUserInfo } from "../../store/actions";
import Sidebar from "./Sidebar";

// const drawerWidth = 240;

const AdminLayout = () => {
  const navigate = useNavigate();
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );

  // Redirect unauthorized users
  if (!authInfo?.roles?.includes("user.admin")) {
    navigate("/login");
  }

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <Sidebar />

      {/* Main Content */}
      <Box
        component="main"
        // sx={{ flexGrow: 1, p: 3, mt: 8 }}
      >
        <Outlet /> {/* This renders the nested child components */}
      </Box>
    </Box>
  );
};

export default AdminLayout;
