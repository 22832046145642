// import { useEffect, useState } from "react";
// import useAxiosPrivate from "../../../../common/useAxiosPrivate";
// import { TableCell, TableRow, Typography } from "@mui/material";

// const ScheduledChassisQueue: React.FC<{ line: string }> = ({ line }) => {
//   const axiosPrivate = useAxiosPrivate();
//   const [scheduledChassisData, setScheduledChassisData] = useState<any>([]);
//   const [isCollapsed, setIsCollapsed] = useState(false);
//   const [editIndex, setEditIndex] = useState<number | null>(null); // Track the row being edited
//   const [tempValue, setTempValue] = useState(""); // Temporary state for priority value

//   const fetchScheduledChassisData = async () => {
//     try {
//       const response = await axiosPrivate.get(
//         `get_scheduled_chassis_queues_by_line/${line}`
//       );
//       let scheduledChassisQueues = response.data;

//       const missingItems = 20 - scheduledChassisQueues.length;
//       const generatedItems = Array.from({ length: missingItems }, () => ({
//         id: null,
//         chassisNo: "",
//         model: "",
//         dealer: "",
//         notes: "",
//         priorityNo: "",
//         duplicate: null,
//       }));

//       setScheduledChassisData([...scheduledChassisQueues, ...generatedItems]);
//     } catch (error) {
//       console.error("Error fetching scheduled chassis data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchScheduledChassisData();
//   }, [line]);

//   const handleCellClick = (index: number, currentValue: string) => {
//     if (!scheduledChassisData[index].chassisNo) return; // Only allow editing if chassisNo exists
//     setEditIndex(index);
//     setTempValue(currentValue);
//   };

//   const handlePriorityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setTempValue(e.target.value);
//   };

//   const handleKeyDown = (
//     e: React.KeyboardEvent<HTMLInputElement>,
//     index: number
//   ) => {
//     if (e.key === "Enter") {
//       const newData = [...scheduledChassisData];
//       newData[index].priorityNo = tempValue;
//       setScheduledChassisData(newData);
//       setEditIndex(null); // Exit edit mode
//     }
//   };

//   return (
//     <>
//       <TableRow
//         style={{
//           backgroundColor: "#F9F9F9",
//           fontFamily: "League Spartan, sans-serif",
//         }}
//       >
//         <TableCell
//           rowSpan={isCollapsed ? 1 : scheduledChassisData.length + 1}
//           className="cursor-pointer"
//           onClick={() => setIsCollapsed(!isCollapsed)}
//         >
//           <div className="d-flex align-items-center justify-content-between gap-1">
//             <Typography
//               variant="body1"
//               style={{ fontFamily: "League Spartan, sans-serif" }}
//             >
//               Scheduled Chassis
//             </Typography>
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="16"
//               height="9"
//               viewBox="0 0 16 9"
//               fill="none"
//               style={{
//                 transform: isCollapsed ? "rotate(0deg)" : "rotate(180deg)",
//                 transition: "0.3s ease",
//               }}
//             >
//               <path
//                 d="M2 2L8 8L14 2"
//                 stroke="#25282B"
//                 strokeWidth="2"
//                 strokeLinecap="square"
//                 strokeLinejoin="round"
//               />
//             </svg>
//           </div>
//         </TableCell>
//       </TableRow>

//       {!isCollapsed &&
//         scheduledChassisData.map((row: any, subRowIndex: number) => (
//           <TableRow
//             key={subRowIndex}
//             style={{
//               backgroundColor: "#F9F9F9",
//               fontFamily: "League Spartan, sans-serif",
//             }}
//           >
//             {/* Priority Column */}
//             <TableCell
//               onClick={() => handleCellClick(subRowIndex, row.priorityNo)}
//               style={{ cursor: row.chassisNo ? "pointer" : "default" }}
//             >
//               {editIndex === subRowIndex ? (
//                 <input
//                   style={{ width: "100%" }}
//                   type="text"
//                   pattern="(^[1-9][0-9]*[ab]?$)?"
//                   value={tempValue}
//                   onChange={handlePriorityChange}
//                   onKeyDown={(e) => handleKeyDown(e, subRowIndex)}
//                   autoFocus
//                 />
//               ) : (
//                 <Typography
//                   variant="body1"
//                   style={{
//                     fontFamily: "League Spartan, sans-serif",
//                     color: row.chassisNo ? "#000" : "#aaa",
//                   }}
//                 >
//                   {row.priorityNo ?? ""}
//                 </Typography>
//               )}
//             </TableCell>

//             {/* Chassis Number Column */}
//             <TableCell>
//               <input
//                 style={{ width: "100%" }}
//                 type="text"
//                 value={row.chassisNo}
//                 onChange={(e) => {
//                   const newData = [...scheduledChassisData];
//                   newData[subRowIndex].chassisNo = e.target.value;
//                   setScheduledChassisData(newData);
//                 }}
//               />
//             </TableCell>

//             {/* Model Column */}
//             <TableCell>
//               <Typography
//                 variant="body1"
//                 style={{ fontFamily: "League Spartan, sans-serif" }}
//               >
//                 {row.model}
//               </Typography>
//             </TableCell>

//             {/* Notes Column */}
//             <TableCell>
//               <input
//                 type="text"
//                 value={row.notes}
//                 onChange={(e) => {
//                   const newData = [...scheduledChassisData];
//                   newData[subRowIndex].notes = e.target.value;
//                   setScheduledChassisData(newData);
//                 }}
//               />
//             </TableCell>

//             {/* Empty Column for alignment */}
//             <TableCell style={{ textAlign: "center" }}>
//               <Typography
//                 variant="body1"
//                 style={{ fontFamily: "League Spartan, sans-serif" }}
//               ></Typography>
//             </TableCell>
//           </TableRow>
//         ))}
//     </>
//   );
// };

// export default ScheduledChassisQueue;

import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";
import { TableCell, TableRow, Typography } from "@mui/material";
import "./assembly.css";
import { toast } from "react-toastify";
import { validatePriorityNo } from "./utils";

interface ScheduledChassisQueueProps {
  line: string;
  refresh: boolean;
}

const ScheduledChassisQueue: React.FC<ScheduledChassisQueueProps> = (props) => {
  const { line, refresh } = props;
  const axiosPrivate = useAxiosPrivate();
  const [scheduledChassisData, setScheduledChassisData] = useState<any>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [editIndex, setEditIndex] = useState<{
    field: string;
    index: number;
  } | null>(null);
  const [tempValue, setTempValue] = useState("");

  const fetchScheduledChassisData = async () => {
    try {
      const response = await axiosPrivate.get(
        `get_scheduled_chassis_queues_by_line/${line}`
      );
      let scheduledChassisQueues = response.data;

      const missingItems = 20 - scheduledChassisQueues.length;
      const generatedItems = Array.from({ length: missingItems }, () => ({
        id: null,
        chassisNo: "",
        model: "",
        dealer: "",
        notes: "",
        priorityNo: "",
        duplicate: null,
      }));

      setScheduledChassisData([...scheduledChassisQueues, ...generatedItems]);
    } catch (error) {
      console.error("Error fetching scheduled chassis data:", error);
    }
  };

  useEffect(() => {
    fetchScheduledChassisData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line, refresh]);

  const handleCellClick = (
    index: number,
    field: string,
    currentValue: string
  ) => {
    setEditIndex({ field, index });
    setTempValue(currentValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(e.target.value);
  };

  const handleKeyDown = async (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
    field: string
  ) => {
    if (e.key === "Enter") {
      const newData = [...scheduledChassisData];
      newData[index][field] = tempValue;
      try {
        if (field === "chassisNo") {
          // Call API for chassis number update
          await updateChassisNoAPI(newData[index].id, tempValue);
        } else if (field === "priorityNo") {
          // Call API for priority value update
          await updatePriorityNoAPI(newData[index].id, tempValue);
        }
      } catch (error) {
        console.error("Error updating data:", error);
      }
      setScheduledChassisData(newData);
      setEditIndex(null);
    }
  };

  const updateChassisNoAPI = async (id: string, chassisNo: string) => {
    try {
      await toast.promise(
        axiosPrivate.post("update_chassis_to_scheduled_chassis_queues", {
          id: id,
          chassisNo: chassisNo || "",
          line: line,
        }),
        {
          pending: "Request pending",
        }
      );
      toast.success("Request Success..! 👌");
      fetchScheduledChassisData();
    } catch (error: any) {
      toast.error(error?.response?.data?.detail || "Not Found!");
      fetchScheduledChassisData();
    }
  };

  const updatePriorityNoAPI = async (id: string, priorityNo: string) => {
    const prodTargetResponse = await axiosPrivate.get(
      `get_weekly_target_by_line/${line}`
    );
    const prodTargetValue = prodTargetResponse.data;
    const isPriorityValidated = validatePriorityNo(priorityNo, prodTargetValue);
    if (priorityNo > prodTargetValue || !isPriorityValidated) {
      toast.error("Priority no has exceeded the production target!");
      fetchScheduledChassisData();
    } else {
      try {
        await toast.promise(
          axiosPrivate.post("update_priority_to_scheduled_chassis_queues", {
            id: id,
            priorityNo: priorityNo ?? "",
          }),
          {
            pending: "Request pending",
          }
        );
        toast.success("Request Success..! 👌");
        fetchScheduledChassisData();
      } catch (error: any) {
        toast.error(error?.response?.data?.detail || "Not Found!");
        fetchScheduledChassisData();
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow
        style={{
          backgroundColor: "#F9F9F9",
          fontFamily: "League Spartan, sans-serif",
        }}
      >
        <TableCell
          rowSpan={isCollapsed ? 1 : scheduledChassisData.length + 1}
          className="cursor-pointer"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <div className="d-flex align-items-center justify-content-between gap-1">
            <Typography
              variant="body1"
              style={{ fontFamily: "League Spartan, sans-serif" }}
            >
              Scheduled Chassis
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="9"
              viewBox="0 0 16 9"
              fill="none"
              style={{
                transform: isCollapsed ? "rotate(0deg)" : "rotate(180deg)",
                transition: "0.3s ease",
              }}
            >
              <path
                d="M2 2L8 8L14 2"
                stroke="#25282B"
                strokeWidth="2"
                strokeLinecap="square"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </TableCell>
      </TableRow>

      {!isCollapsed &&
        scheduledChassisData.map((row: any, subRowIndex: number) => (
          <TableRow
            key={subRowIndex}
            style={{
              backgroundColor: "#F9F9F9",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            {/* Priority Column */}
            <TableCell
              onClick={() =>
                handleCellClick(subRowIndex, "priorityNo", row.priorityNo)
              }
              style={{
                cursor: row.chassisNo ? "pointer" : "default",
                height: "30px",
              }}
            >
              {editIndex?.index === subRowIndex &&
              editIndex.field === "priorityNo" ? (
                <input
                  style={{ width: "100%" }}
                  type="text"
                  pattern="(^[1-9][0-9]*[ab]?$)?"
                  value={tempValue}
                  onChange={handleInputChange}
                  disabled={!row.chassisNo}
                  onKeyDown={(e) => handleKeyDown(e, subRowIndex, "priorityNo")}
                  autoFocus
                />
              ) : (
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "League Spartan, sans-serif",
                    color: row.chassisNo ? "#000" : "#aaa",
                  }}
                >
                  {row.priorityNo || ""}
                </Typography>
              )}
            </TableCell>

            {/* Chassis Number Column */}
            <TableCell
              onClick={() =>
                handleCellClick(subRowIndex, "chassisNo", row.chassisNo)
              }
              style={{ cursor: "pointer" }}
            >
              {editIndex?.index === subRowIndex &&
              editIndex.field === "chassisNo" ? (
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={tempValue}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e, subRowIndex, "chassisNo")}
                  autoFocus
                />
              ) : (
                <Typography
                  variant="body1"
                  style={{ fontFamily: "League Spartan, sans-serif" }}
                >
                  {row.chassisNo || ""}
                </Typography>
              )}
            </TableCell>

            {/* Alert Column */}
            <TableCell>
              <Typography
                variant="body1"
                style={{ fontFamily: "League Spartan, sans-serif" }}
              >
                {row.status}
              </Typography>
            </TableCell>
            {/* Model Column */}
            <TableCell>
              <Typography
                variant="body1"
                style={{ fontFamily: "League Spartan, sans-serif" }}
              >
                {row.model}
              </Typography>
            </TableCell>

            {/* Notes Column */}
            {/* <TableCell
              onClick={() => handleCellClick(subRowIndex, "notes", row.notes)}
              style={{ cursor: "pointer" }}
            >
              {editIndex?.index === subRowIndex &&
              editIndex.field === "notes" ? (
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={tempValue}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e, subRowIndex, "notes")}
                  autoFocus
                />
              ) : (
                <Typography
                  variant="body1"
                  style={{ fontFamily: "League Spartan, sans-serif" }}
                >
                  {row.notes || ""}
                </Typography>
              )}
            </TableCell> */}
            <TableCell>
              <Typography
                variant="body1"
                style={{ fontFamily: "League Spartan, sans-serif" }}
              >
                {row.notes}
              </Typography>
            </TableCell>

            {/* Empty Column for alignment */}
            <TableCell style={{ textAlign: "center" }}>
              <Typography
                variant="body1"
                style={{ fontFamily: "League Spartan, sans-serif" }}
              ></Typography>
            </TableCell>
          </TableRow>
        ))}
    </React.Fragment>
  );
};

export default ScheduledChassisQueue;
