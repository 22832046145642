import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../../../store/actions";

export default function ChassisTable(props: any) {
 const authInfo: IUserInfo = useSelector(
   (state: any) => state.userAuthInfoState
 );
 const { chassisData, lineValue, handlePostChassis } = props;
 const [editingIndex, setEditingIndex] = useState<number | null>(null);
 const [chassisVal, setChassisVal] = useState("");

 const handleEditChassis = (item: any, index: number) => {
   setEditingIndex(index);
   setChassisVal(item.chassisNo);
 };

 const handleChangeChassis = (event: React.ChangeEvent<HTMLInputElement>) => {
   setChassisVal(event.target.value);
 };

 const handleBlur = (item: any) => {
   if (chassisVal.trim() !== "" && item.chassisNo !== chassisVal) {
     handlePostChassis(item, lineValue, chassisVal);
   }
   setEditingIndex(null);
 };

 const handleKeyDown = (
   event: React.KeyboardEvent<HTMLInputElement>,
   item: any
 ) => {
   if (event.key === "Enter") {
     handleBlur(item);
   }
 };
  return (
    <TableContainer
      sx={{ maxWidth: "300px" }}
      className="p-0"
      component={Paper}
      style={{ marginTop: "8px", height: "fit-content" }}
    >
      {chassisData.length > 0 ? (
        <Table size="small" className="p-0">
          <TableHead>
            <TableRow>
              {authInfo.roles?.includes("production-manager") ? (
                <TableCell
                  style={{ width: "20px", backgroundColor: "rgb(59, 17, 80)" }}
                >
                  <Typography
                    className="font-semibold"
                    variant="subtitle1"
                    style={{
                      color: "white",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    SL
                  </Typography>
                </TableCell>
              ) : (
                <TableCell style={{ width: "20px" }}>
                  <Typography
                    className="font-semibold"
                    variant="subtitle1"
                    style={{
                      color: "#c7c7c7",
                      fontFamily: "League Spartan, sans-serif",
                    }}
                  >
                    SL
                  </Typography>
                </TableCell>
              )}

              <TableCell
                style={{ width: "90px", backgroundColor: "rgb(59, 17, 80)" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Priority No
                </Typography>
              </TableCell>
              <TableCell
                style={{ width: "90px", backgroundColor: "rgb(59, 17, 80)" }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "white",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Chassis No
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chassisData.map((item: any, index: number) => (
              <TableRow key={index}>
                {authInfo.roles?.includes("production-manager") ? (
                  <TableCell>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      {/* {index + 1} */}
                      {chassisData.length - index}
                    </Typography>
                  </TableCell>
                ) : (
                  <TableCell>
                    <Typography
                      variant="body1"
                      style={{
                        fontFamily: "League Spartan, sans-serif",
                        color: "#c7c7c7",
                      }}
                    >
                      {/* {index + 1} */}
                      {chassisData.length - index}
                    </Typography>
                  </TableCell>
                )}
                <TableCell>
                  <Typography>{item.priorityNo}</Typography>
                </TableCell>
                <TableCell
                  onClick={() =>
                    authInfo.roles?.includes("user.admin") &&
                    handleEditChassis(item, index)
                  }
                  style={{
                    cursor: authInfo.roles?.includes("user.admin")
                      ? "pointer"
                      : "default",
                  }}
                >
                  {editingIndex === index ? (
                    <input
                      style={{ width: "100%" }}
                      type="text"
                      value={chassisVal}
                      onChange={handleChangeChassis}
                      onBlur={() => handleBlur(item)}
                      onKeyDown={(event) => handleKeyDown(event, item)}
                      autoFocus
                    />
                  ) : (
                    <Typography>{item.chassisNo}</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography
          variant="body1"
          style={{
            marginTop: "8px",
            paddingLeft: "5px",
            fontFamily: "League Spartan, sans-serif",
          }}
        >
          No data found..!
        </Typography>
      )}
    </TableContainer>
  );
}
