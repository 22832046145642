/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, Paper } from "@mui/material";
import { DailyReviewPresenter } from "../dailyReview/dailyReview-presenter";
import { Fragment, useEffect, useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import PrepDataTable from "./PrepDataTable";
import ProductionLineData from "./PrductionLineData";
import ActiveAlertsData from "./ActiveAlertsTable";
// import ChassisArrivedTable from "./ChassisArrivedTable";
import FinalReports from "./FinalReports";

export default function ProductionReview() {
  const axiosPrivate = useAxiosPrivate();
  let dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
  const [productionLineForMain, setProductionLineDataMain] = useState([]);
  const [productionLineForCRV, setProductionLineDataCRV] = useState([]);
  const [activeAlertsData, setActiveAlertsData] = useState([]);
  const [chassisDataForMain, setChassisDataForMain] = useState({});
  const [chassisDataForCRV, setChassisDataForCRV] = useState({});
  const [prepData, setPrepData] = useState({});
  const lineVal = ["main", "crv"];

  const fetchProductionLineData = async () => {
    try {
      const responseDataForMain =
        await dailyReviewPresenter.fetchProductionReviewData("main");

      const responseDataForCRV =
        await dailyReviewPresenter.fetchProductionReviewData("crv");
      setProductionLineDataMain(responseDataForMain);
      setProductionLineDataCRV(responseDataForCRV);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchActiveAlertsData = async () => {
    try {
      const res = await axiosPrivate.get(`get_active_alerts`);
      // console.log(res);
      setActiveAlertsData(res.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getChassisScheduledAndArrivedData = async () => {
    try {
      let deliveryQueues = [];
      const deliveryQueueResponse = await axiosPrivate.get(
        "get_all_chassis_delivery_queues"
      );
      deliveryQueues = deliveryQueueResponse.data;
      const mainArrivalQueueResponse = await axiosPrivate.get(
        `get_chassis_arrival_queues_by_line/main`
      );
      const crvArrivalQueueResponse = await axiosPrivate.get(
        `get_chassis_arrival_queues_by_line/crv`
      );
      let mainArrivalQueues = mainArrivalQueueResponse.data;
      let crvArrivalQueues = crvArrivalQueueResponse.data;
      let allMainData = {
        "Chassis to be Delivered": deliveryQueues,
        "Chassis Arrived": mainArrivalQueues,
      };
      let allCrvData = {
        "Chassis to be Delivered": deliveryQueues,
        "Chassis Arrived": crvArrivalQueues,
      };
      setChassisDataForMain(allMainData);
      setChassisDataForCRV(allCrvData);
    } catch (error) {
      console.log("Chassis Data error", error);
    }
  };

  const getAllPrepData = async () => {
    try {
      const data = await dailyReviewPresenter.getAllPrepData(axiosPrivate);
      setPrepData(data);
      // const filteredData = Object.keys(data).reduce((acc:any, key) => {
      //   if (key !== "CNC Doors & Drawers") {
      //     acc[key] = data[key];
      //   }
      //   return acc;
      // }, {});
      // setPrepData(filteredData);
    } catch (error) {}
  };

  useEffect(() => {
    fetchProductionLineData();
    getChassisScheduledAndArrivedData();
    getAllPrepData();
    fetchActiveAlertsData();

    let id = setInterval(() => {
      fetchProductionLineData();
      getChassisScheduledAndArrivedData();
      getAllPrepData();
      fetchActiveAlertsData();
    }, 5 * 1000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1 className="text-start mb-0">CRUSADER PRODUCTION SYSTEM</h1>
      <Box
        className="mx-auto mb-5"
        sx={{ flexGrow: 1 }}
        style={{ maxWidth: "3800px" }}
      >
        {/* <Grid item xs={12}>
          {Object.keys(chassisDataForMain).map((rowName, i) =>
            i === 0 ? (
              // <ChassisDeliveredTable
              //   chassisData={chassisDataForMain}
              //   rowName={rowName}
              //   key={i}
              // />
              <></>
            ) : (
              <ChassisArrivedTable
                mainArrivedData={chassisDataForMain}
                crvArrivedData={chassisDataForCRV}
                rowName={rowName}
                key={i}
              />
            )
          )}
        </Grid> */}

        <Paper
          elevation={3}
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            // marginTop: "12px",
          }}
        >
          <h2
            className="mb-0"
            style={{
              fontSize: "1.6vmin",
              fontFamily: "Roboto, Helvetica,Arial,sans-serif",
              fontWeight: "700",
            }}
          >
            SUB ASSEMBLY
          </h2>
          <Grid container spacing={3} className="mt-1">
            {lineVal.map((line, index) => (
              <Fragment key={index}>
                {Object.keys(prepData).map((row, i) => (
                  <Grid item xs={1.71} key={i} className="pt-0">
                    <PrepDataTable
                      rowName={row}
                      prepData={prepData}
                      line={line}
                    />
                  </Grid>
                ))}
              </Fragment>
            ))}
          </Grid>
        </Paper>
        <Grid container className="prod-line-summary__grid mt-2">
          <Grid item xs={12} className="pt-0">
            <ProductionLineData
              prodLineData={productionLineForMain}
              lineName="MAIN LINE"
            />
          </Grid>
          <Grid item xs={12} className="pt-0">
            <ProductionLineData
              prodLineData={productionLineForCRV}
              lineName="CRV LINE"
            />
          </Grid>
        </Grid>
        <Grid container className="mt-1">
          <Grid container className="">
            <Grid item xs={12}>
              <FinalReports />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ActiveAlertsData
              activeAlertData={activeAlertsData}
              // legends={legends}
            />
          </Grid>
        </Grid>

        {/* <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          className="my-3"
        >
          {legends.map((legend, index) => (
            <Grid item key={index}>
              <Box display="flex" alignItems="center" marginRight="10px">
                <Box
                  sx={{
                    width: 40,
                    height: 20,
                    backgroundColor: legend.color,
                    border: legend.border || "none",
                    marginRight: 1,
                  }}
                />
                <Typography variant="body2">{legend.label}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid> */}
      </Box>
    </div>
  );
}
