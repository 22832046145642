import React, { useEffect, useState } from "react";
import "./styles.css";
import AssemblyTable from "./components/assembly/AssemblyTable";
import Preparation from "./components/Preparation/Preparation";
import ChassisTable from "./components/ChassisTable/ChassisTable";
import ConfirmationModal from "./components/ConfirmationModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { DailyReviewPresenter } from "./dailyReview-presenter";
import { deepClone } from "./components/assembly/utils";
import useAxiosPrivate from "../../common/useAxiosPrivate";
import ProductionLineTimer from "./components/ProductionLineTimer/ProductionLineTimer";
import _ from "lodash";

const { REACT_APP_API_BASE_URL } = process.env;

export default function DailyReview() {
  const axiosPrivate = useAxiosPrivate();
  let dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
  const [selectedLineValue, setSelectedLineValue] = useState("main-line");
  const [refresh, setRefresh] = useState(true);
  const [tab, setTab] = useState(1);
  const [prodTargetVal, setProdTargetVal] = useState("");
  const [chassisData, setChassisData] = useState([]);
  const [crvChassisData, setCrvChassisData] = useState([]);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [timeReeportLoader, setTimeReportLoader] = useState(false);
  const [simulatorData, setSimulatorData] = useState({});
  const [simulatorDataCopy, setSimulatorDataCopy] = useState({});
  const [responseContent, setResponseContent] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedChassisNo, setHighlightedChassisNo] = useState(null);

  const authInfo = useSelector((state) => {
    return state.userAuthInfoState;
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
  }, []);

  const fetchData = async () => {
    try {
      const responseContent =
        await dailyReviewPresenter.fetchProductionReviewData(selectedLineValue);
      setResponseContent(responseContent);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchProdTargetVal = async () => {
    const line = _.first(selectedLineValue.split("-"));
    const response = await axiosPrivate.get(
      `get_weekly_target_by_line/${line}`
    );
    setProdTargetVal(response.data);
  };
  const getChassisPreparationData = async () => {
    const mainResponse = await axiosPrivate.get(
      `get_chassis_preparation_queues_by_line/main`
    );
    const crvResponse = await axiosPrivate.get(
      `get_chassis_preparation_queues_by_line/crv`
    );
    setChassisData(mainResponse.data);
    setCrvChassisData(crvResponse.data);
  };
  const preparePreparationQueue = async () => {
    // }
    const line = _.first(selectedLineValue.split("-"));
    try {
      await toast.promise(
        axiosPrivate.post(
          "move_chassis_from_scheduled_chassis_queue_to_preparation_queue",
          { line: line }
        ),
        {
          pending: "Request pending",
        }
      );
      await getChassisPreparationData();
      await fetchData();
      setRefresh(!refresh);
      toast.success("Request Success..!");
    } catch (error) {
      toast.error(error.response.data.detail);
      await getChassisPreparationData();
      await fetchData();
      setRefresh(!refresh);
    }
  };
  const getChassisPreparationQueueAndProductionLines = async () => {
    await getChassisPreparationData();
    await fetchData();
  };
  React.useEffect(() => {
    fetchData();
    fetchProdTargetVal();
    getChassisPreparationData();

    let id = setInterval(() => {
      fetchData();
      fetchProdTargetVal();
      getChassisPreparationData();
    }, 30 * 1000);
    return () => clearInterval(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineValue]);

  const handleLineValue = (e) => {
    setSelectedLineValue(e.target.value);
  };
  const updateTabToggle = (id) => {
    setTab(id);
    if (id === 1) {
      console.log("event", selectedLineValue);
      setSelectedLineValue("main-line");
    }
  };

  const resetChassisPreparationData = async () => {
    const line = _.first(selectedLineValue.split("-"));
    try {
      await toast.promise(
        axiosPrivate.post("reset_chassis_preparation_queue", { line: line }),
        {
          pending: "Request pending",
        }
      );
      await getChassisPreparationData();
      await fetchData();
      setRefresh(!refresh);
      toast.success("All data moved to Chassis Arrived");
    } catch (error) {
      console.log("error", error);
      toast.error("Request Failed");
    }
  };

  const resetProductionLineData = async () => {
    const line = _.first(selectedLineValue.split("-"));
    try {
      await toast.promise(
        axiosPrivate.post("reset_production_line", { line: line }),
        {
          pending: "Request pending",
        }
      );
      await getChassisPreparationData();
      await fetchData();
      toast.success("Request Success..!");
    } catch (error) {
      console.log("error", error);
      toast.error("Request Failed");
    }
  };

  const handlePostChassisFromChassisTable = async (
    item,
    lineVal,
    chassisVal
  ) => {
    const requestBody = {
      chassisNo: chassisVal,
      model: item.model,
      priorityNo: item.priorityNo,
      line: item.line,
    };

    try {
      await toast.promise(
        axiosPrivate.put(
          `update_chassis_preparation_queue_by_chassis_no/${item.id}`,
          requestBody
        ),
        {
          pending: "Request pending",
        }
      );
      getChassisPreparationData();
      toast.success("Request Success");
    } catch (error) {
      console.log("error", error);
      toast.error("Invalid Input");
    }
  };

  const handleDownloadAndPrintPdf = async (event) => {
    // const dailyReviewPresenter = new DailyReviewPresenter(axiosPrivate);
    event.preventDefault(); // Prevent the default anchor click behavior
    setPdfLoader(true);
    const pdfUrl = event.currentTarget.href;
    await dailyReviewPresenter.getPdfUrl(pdfUrl);
    setPdfLoader(false);
  };

  const handleDownloadTimeRecordCSV = async () => {
    try {
      toast.info("Request pending...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false, // Keeps the toast open until manually closed
        toastId: "requestPending", // Unique id for this specific toast
      });
      setTimeReportLoader(true);
      const line = _.first(selectedLineValue.split("-"));
      const response = await axiosPrivate.post(
        "get_production_lines_time_record_as_csv",
        { line: line },
        { responseType: "blob" }
      );
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "download.csv";
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toast.dismiss("requestPending"); // Close the request pending toast
      toast.success("File downloaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toast.dismiss("requestPending"); // Close the request pending toast
      toast.error("Error downloading file. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setTimeReportLoader(false);
    }
  };

  const handleDownloadPreparationTimeRecordCSV = async () => {
    try {
      toast.info("Request pending...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false, // Keeps the toast open until manually closed
        toastId: "requestPending", // Unique id for this specific toast
      });
      setTimeReportLoader(true);
      const response = await axiosPrivate.post(
        "get_chassis_preparation_queue_time_report_as_csv",
        { responseType: "blob" }
      );
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "download.csv";
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toast.dismiss("requestPending"); // Close the request pending toast
      toast.success("File downloaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading CSV:", error);
      toast.dismiss("requestPending"); // Close the request pending toast
      toast.error("Error downloading file. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setTimeReportLoader(false);
    }
  };

  const handleShowConfirmModal = () => setShowConfirmModal(true);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);

  const handleConfirmReset = () => {
    resetChassisPreparationData();
    handleCloseConfirmModal();
  };

  // --------------------------------
  const updateSimulatorData = (responseData) => {
    setSimulatorData(responseData);
    setSimulatorDataCopy(deepClone(responseData));
  };

  const handleSearch = () => {
    if (!searchTerm.trim()) return;

    const foundChassis = responseContent.find(
      (station) => station.chassisNo === searchTerm
    );

    if (foundChassis) {
      // Clear highlight first, then re-set it after a short delay
      setHighlightedChassisNo(null);

      setTimeout(() => {
        setHighlightedChassisNo(searchTerm);
      }, 100); // Small delay to trigger re-render
    } else {
      toast.error("Chassis no not found!");
    }
  };

  const hasPrepRole = authInfo.roles?.some((role) => role.includes("prep"));
  if (hasPrepRole) {
    return (
      <div className="position-relative">
        {/* <Timer selectedLineValue={selectedLineValue} /> */}
        <div className="mx-auto" style={{ maxWidth: "1500px" }}>
          <h1 className="fw-semibold mb-3 mt-2">Crusader Production Review</h1>
          <div className="d-flex gap-3">
            <Preparation />
            <div className="flex-column gap-5 flex-grow-1">
              <h5 className="mt-2">Main Line Preparation</h5>
              <ChassisTable
                chassisData={chassisData}
                productionTargetVal={prodTargetVal}
              />
              <h5 className="mt-2">CRV Line Preparation</h5>
              <ChassisTable
                chassisData={crvChassisData}
                productionTargetVal={prodTargetVal}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="position-relative">
        {(authInfo.roles?.includes("user.admin") ||
          authInfo.roles?.includes("production-manager") ||
          authInfo.roles?.includes("production-scheduler")) && (
          <nav className="d-flex justify-content-center align-items-center my-4">
            <div className="tab-container d-flex">
              <div className="selector"></div>
              <button
                className={`m-0 d-flex align-items-center gap-2 flex-row-reverse ${
                  tab === 1 ? "active" : ""
                }`}
                onClick={() => updateTabToggle(1)}
              >
                Assembly
              </button>
              <button
                className={`m-0 ${tab === 2 ? "active" : ""}`}
                onClick={() => updateTabToggle(2)}
              >
                Preparation
              </button>
            </div>
          </nav>
        )}
        {/* <Timer selectedLineValue={selectedLineValue} /> */}
        <div
          className="d-flex justify-content-between align-items-center mx-auto h-full mt-4"
          style={{ maxWidth: "1500px" }}
        >
          <div className="d-flex align-items-center gap-5">
            <h1 className="fw-semibold mb-0">Crusader Production System</h1>
            {tab === 1 ? (
              <>
                <div className="d-flex">
                  <select
                    className="form-select"
                    aria-label="Table Select"
                    onChange={handleLineValue}
                  >
                    <option className="text-black" value="main-line">
                      {" "}
                      Main Line{" "}
                    </option>
                    <option className="text-black" value="crv-line">
                      {" "}
                      CRV Line{" "}
                    </option>
                  </select>
                  {authInfo.roles?.includes("user.admin") ||
                  authInfo.roles?.includes("production-manager") ? (
                    <>
                      <button
                        className="btn btn-secondary ms-2"
                        onClick={resetProductionLineData}
                      >
                        Reset
                      </button>
                      <button
                        onClick={handleDownloadTimeRecordCSV}
                        className="btn blue-btn ms-3"
                      >
                        {timeReeportLoader
                          ? "Processing..."
                          : "Download Time Report"}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <ProductionLineTimer
                  selectedLineValue={selectedLineValue}
                  refreshProdLine={fetchData}
                  refreshChassisPreparation={getChassisPreparationData}
                />
              </>
            ) : (
              <>
                <a
                  href={`${REACT_APP_API_BASE_URL}/api/get_chassis_preparation_queue_report_as_pdf`}
                  onClick={handleDownloadAndPrintPdf}
                  download="preparation.pdf"
                  id="download-button"
                  className="btn blue-btn"
                  type="button"
                >
                  {pdfLoader ? "Generating..." : "Generate Pdf"}
                </a>
                <button
                  onClick={handleDownloadPreparationTimeRecordCSV}
                  className="btn blue-btn ms-3"
                >
                  {timeReeportLoader ? "Processing..." : "Download Time Report"}
                </button>
              </>
            )}
          </div>
        </div>
        {/* 🔍 Search Bar for Chassis Number */}
        <div
          className="d-flex justify-content-start mx-auto mt-3"
          style={{ maxWidth: "1500px" }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search Chassis Number..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: "300px" }}
          />
          <button className="btn blue-btn ms-2" onClick={handleSearch}>
            Search
          </button>
        </div>
        <>
          {tab === 1 ? (
            <div className="mx-auto" style={{ maxWidth: "1500px" }}>
              <div className="d-flex flex-column flex-lg-row gap-5 gap-lg-3">
                <AssemblyTable
                  responseContent={responseContent}
                  setResponseContent={setResponseContent}
                  fetchData={fetchData}
                  selectedLineValue={selectedLineValue}
                  productionTargetVal={prodTargetVal}
                  updateSimulatorData={updateSimulatorData}
                  layoutData={simulatorData}
                  layoutDataCopy={simulatorDataCopy}
                  refresh={refresh}
                  getChassisPreparationQueueAndProductionLines={
                    getChassisPreparationQueueAndProductionLines
                  }
                  highlightedChassisNo={highlightedChassisNo}
                />
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center">
                    {selectedLineValue === "main-line" && (
                      <h5 className="mb-0" style={{ paddingLeft: "18px" }}>
                        Main Line Preparation
                      </h5>
                    )}
                    {selectedLineValue === "crv-line" && (
                      <h5 className="mb-0" style={{ paddingLeft: "18px" }}>
                        CRV Line Preparation
                      </h5>
                    )}
                    {(authInfo.roles?.includes("user.admin") ||
                      authInfo.roles?.includes("production-manager") ||
                      authInfo.roles?.includes("production-scheduler")) && (
                      <>
                        <button
                          className={`btn btn-secondary ms-2 ${
                            tab === 1 ? "d-block" : "d-none"
                          }`}
                          onClick={preparePreparationQueue}
                        >
                          Prepare
                        </button>
                        <button
                          className={`btn btn-secondary ms-2 ${
                            tab === 1 ? "d-block" : "d-none"
                          }`}
                          onClick={handleShowConfirmModal}
                        >
                          Reset
                        </button>
                      </>
                    )}
                  </div>
                  <ChassisTable
                    chassisData={
                      selectedLineValue === "main-line"
                        ? chassisData
                        : crvChassisData
                    }
                    lineValue={selectedLineValue}
                    handlePostChassis={handlePostChassisFromChassisTable}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="mx-auto" style={{ maxWidth: "1500px" }}>
              <div className="d-flex flex-column flex-lg-row gap-5 gap-lg-4">
                <Preparation />
                <div className="d-flex flex-row flex-lg-column justify-content-center justify-content-lg-start gap-5 gap-lg-4 flex-grow-1">
                  <div>
                    <h5 className="pl-2 !important">Main Line Preparation</h5>
                    <ChassisTable
                      chassisData={chassisData}
                      lineValue={"main-line"}
                      handlePostChassis={handlePostChassisFromChassisTable}
                    />
                  </div>
                  <div>
                    <h5 className="mtt-lg-5 pl-lg-2 !important">
                      CRV Line Preparation
                    </h5>
                    <ChassisTable
                      chassisData={crvChassisData}
                      lineValue={"crv-line"}
                      handlePostChassis={handlePostChassisFromChassisTable}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <ConfirmationModal
            show={showConfirmModal}
            onClose={handleCloseConfirmModal}
            onConfirm={handleConfirmReset}
            title="Confirm Reset"
            body="Are you sure you want to reset the chassis preparation data?"
          />
        </>
      </div>
    );
  }
}
