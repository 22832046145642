import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import useAxiosPrivate from "../../../common/useAxiosPrivate";
import { toast } from "react-toastify";

interface PhoneNumber {
  id: string;
  phoneNumber: string;
}

interface AlertSMSItem {
  id: string;
  statusName: string;
  phoneNumbers: PhoneNumber[];
  message: string;
}

const AlertSMS: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();
  const [items, setItems] = useState<AlertSMSItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<
    PhoneNumber[]
  >([]);
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>("");
  const [editId, setEditId] = useState<string | null>(null);
  const [editPhoneNumber, setEditPhoneNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  // Fetch data from API
  useEffect(() => {
    const fetchAlertNames = async () => {
      try {
        const res = await axiosPrivate.get("get_alert_sms_list");
        setItems(res.data);
      } catch (error) {
        console.error("Error fetching alert names:", error);
      }
    };

    fetchAlertNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle dropdown selection
  const handleSelect = (event: any) => {
    const selected = items.find((item) => item.id === event.target.value);
    if (selected) {
      setSelectedItem(selected.id);
      setSelectedPhoneNumbers(selected.phoneNumbers ?? []);
      setMessage(selected.message ?? "");
    } else {
      setSelectedPhoneNumbers([]);
      setMessage("");
    }
  };

  // Handle adding a new phone number
  const handleAddPhoneNumber = async () => {
    if (!newPhoneNumber.trim()) return;

    try {
      const newPhone: PhoneNumber = {
        id: crypto.randomUUID(),
        phoneNumber: newPhoneNumber,
      };

      const updatedPhoneNumbers = [...selectedPhoneNumbers, newPhone];

      // Update UI state
      const updatedItems = items.map((item) =>
        item.id === selectedItem
          ? { ...item, phoneNumbers: updatedPhoneNumbers }
          : item
      );

      setItems(updatedItems);
      setSelectedPhoneNumbers(updatedPhoneNumbers);
      setNewPhoneNumber("");

      // Send update to backend
      await axiosPrivate.put(
        `update_phone_number_by_status_id/${selectedItem}`,
        newPhone
      );
    } catch (error) {
      console.error("Error adding phone number:", error);
    }
  };

  // Handle deleting a phone number
  const handleDeletePhoneNumber = async (id: string) => {
    try {
      const updatedPhoneNumbers = selectedPhoneNumbers.filter(
        (phone) => phone.id !== id
      );

      // Update UI state
      const updatedItems = items.map((item) =>
        item.id === selectedItem
          ? { ...item, phoneNumbers: updatedPhoneNumbers }
          : item
      );

      setItems(updatedItems);
      setSelectedPhoneNumbers(updatedPhoneNumbers);

      // Send delete request to backend
      await axiosPrivate.post(
        `delete_phone_number_by_status_id/${selectedItem}/${id}`
      );
    } catch (error) {
      console.error("Error deleting phone number:", error);
    }
  };

  // Handle editing a phone number
  const handleEditPhoneNumber = (id: string, phoneNumber: string) => {
    setEditId(id);
    setEditPhoneNumber(phoneNumber);
  };

  // Handle saving an edited phone number
  const handleSavePhoneNumber = async () => {
    if (!editPhoneNumber.trim() || editId === null) return;

    try {
      const updatedPhoneNumbers = selectedPhoneNumbers.map((phone) =>
        phone.id === editId ? { ...phone, phoneNumber: editPhoneNumber } : phone
      );

      // Update UI state
      const updatedItems = items.map((item) =>
        item.id === selectedItem
          ? { ...item, phoneNumbers: updatedPhoneNumbers }
          : item
      );

      const newPhone: PhoneNumber = {
        id: editId,
        phoneNumber: editPhoneNumber,
      };

      setItems(updatedItems);
      setSelectedPhoneNumbers(updatedPhoneNumbers);
      setEditId(null);
      setEditPhoneNumber("");

      // Send update request to backend
      await axiosPrivate.put(
        `update_phone_number_by_status_id/${selectedItem}`,
        newPhone
      );
    } catch (error) {
      console.error("Error updating phone number:", error);
    }
  };

  const handleUpdateMessage = async () => {
    try {
      const updatedItems = items.map((item) =>
        item.id === selectedItem ? { ...item, message } : item
      );

      setItems(updatedItems);

      await toast.promise(
        axiosPrivate.put(`update_message_by_status_id/${selectedItem}`, {
          message,
        }),
        {
          pending: "Request pending",
        }
      );
      toast.success("Message saved successfully!");

      // Send update request to backend
      // await axiosPrivate.put(`update_message_by_status_id/${selectedItem}`, {
      //   message,
      // });
    } catch (error) {
      console.error("Error updating message:", error);
      toast.error("Failed to save the message. Please try again.");
    }
  };

  // return (
  //   <Box p={3}>
  //     <Typography variant="h4">Select Alert Status</Typography>

  //     {/* Dropdown List */}
  //     <Select
  //       value={selectedItem}
  //       onChange={handleSelect}
  //       displayEmpty
  //       fullWidth
  //       sx={{ my: 2 }}
  //     >
  //       <MenuItem value="" disabled>
  //         Select an alert status
  //       </MenuItem>
  //       {items.map((item) => (
  //         <MenuItem key={item.id} value={item.id}>
  //           {item.statusName}
  //         </MenuItem>
  //       ))}
  //     </Select>

  //     {/* Message Input Section */}
  //     {selectedItem && (
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6">Message</Typography>
  //         <TextField
  //           label="Message"
  //           fullWidth
  //           value={message}
  //           onChange={(e) => setMessage(e.target.value)}
  //           sx={{ my: 2 }}
  //         />
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           onClick={handleUpdateMessage}
  //         >
  //           Update Message
  //         </Button>
  //       </Box>
  //     )}

  //     {/* Phone Numbers Section */}
  //     {selectedItem && (
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6">Phone Numbers</Typography>

  //         {selectedPhoneNumbers.length > 0 ? (
  //           <TableContainer component={Paper} sx={{ mt: 2 }}>
  //             <Table>
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell>Phone Number</TableCell>
  //                   <TableCell>Actions</TableCell>
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 {selectedPhoneNumbers.map((phone) => (
  //                   <TableRow key={phone.id}>
  //                     <TableCell>
  //                       {editId === phone.id ? (
  //                         <TextField
  //                           value={editPhoneNumber}
  //                           onChange={(e) => setEditPhoneNumber(e.target.value)}
  //                           fullWidth
  //                         />
  //                       ) : (
  //                         phone.phoneNumber
  //                       )}
  //                     </TableCell>
  //                     <TableCell>
  //                       {editId === phone.id ? (
  //                         <IconButton
  //                           color="primary"
  //                           onClick={handleSavePhoneNumber}
  //                         >
  //                           <SaveIcon />
  //                         </IconButton>
  //                       ) : (
  //                         <IconButton
  //                           color="secondary"
  //                           onClick={() =>
  //                             handleEditPhoneNumber(phone.id, phone.phoneNumber)
  //                           }
  //                         >
  //                           <EditIcon />
  //                         </IconButton>
  //                       )}
  //                       <IconButton
  //                         color="error"
  //                         onClick={() => handleDeletePhoneNumber(phone.id)}
  //                       >
  //                         <DeleteIcon />
  //                       </IconButton>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))}
  //               </TableBody>
  //             </Table>
  //           </TableContainer>
  //         ) : (
  //           <Typography color="textSecondary">
  //             No phone number available
  //           </Typography>
  //         )}

  //         {/* Add New Phone Number Section */}
  //         <Box sx={{ mt: 3 }}>
  //           <TextField
  //             label="New Phone Number"
  //             fullWidth
  //             value={newPhoneNumber}
  //             onChange={(e) => setNewPhoneNumber(e.target.value)}
  //             sx={{ my: 2 }}
  //           />
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             onClick={handleAddPhoneNumber}
  //             disabled={!newPhoneNumber.trim()}
  //           >
  //             Add Phone Number
  //           </Button>
  //         </Box>
  //       </Box>
  //     )}
  //   </Box>
  // );
  // return (
  //   <Box
  //     p={4}
  //     sx={{
  //       maxWidth: 800,
  //       mx: "auto",
  //       bgcolor: "background.paper",
  //       borderRadius: 2,
  //       boxShadow: 3,
  //     }}
  //   >
  //     <Typography variant="h4" gutterBottom fontWeight={600} textAlign="center">
  //       Select Alert Status
  //     </Typography>

  //     {/* Dropdown List */}
  //     <Select
  //       value={selectedItem}
  //       onChange={handleSelect}
  //       displayEmpty
  //       fullWidth
  //       sx={{ my: 0, py: 0, fontSize: 16, borderRadius: 2 }}
  //     >
  //       <MenuItem value="" disabled>
  //         Select an alert status
  //       </MenuItem>
  //       {items.map((item) => (
  //         <MenuItem key={item.id} value={item.id}>
  //           {item.statusName}
  //         </MenuItem>
  //       ))}
  //     </Select>

  //     {/* Message Input Section */}
  //     {selectedItem && (
  //       <Box sx={{ mt: 2 }}>
  //         <Typography variant="h6" fontWeight={500}>
  //           Message
  //         </Typography>
  //         <TextField
  //           label="Message"
  //           fullWidth
  //           value={message}
  //           onChange={(e) => setMessage(e.target.value)}
  //           sx={{ my: 1.5, fontSize: 16, borderRadius: 2 }}
  //         />
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           size="large"
  //           onClick={handleUpdateMessage}
  //         >
  //           Update Message
  //         </Button>
  //       </Box>
  //     )}

  //     {/* Phone Numbers Section */}
  //     {selectedItem && (
  //       <Box sx={{ mt: 3 }}>
  //         <Typography variant="h6" fontWeight={500}>
  //           Phone Numbers
  //         </Typography>

  //         {selectedPhoneNumbers.length > 0 ? (
  //           <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 2 }}>
  //             <Table>
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell sx={{ fontWeight: 600 }}>Phone Number</TableCell>
  //                   <TableCell sx={{ fontWeight: 600 }}>Actions</TableCell>
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 {selectedPhoneNumbers.map((phone) => (
  //                   <TableRow key={phone.id}>
  //                     <TableCell>
  //                       {editId === phone.id ? (
  //                         <TextField
  //                           value={editPhoneNumber}
  //                           onChange={(e) => setEditPhoneNumber(e.target.value)}
  //                           fullWidth
  //                           sx={{ py: 1, fontSize: 16, borderRadius: 2 }}
  //                         />
  //                       ) : (
  //                         phone.phoneNumber
  //                       )}
  //                     </TableCell>
  //                     <TableCell>
  //                       {editId === phone.id ? (
  //                         <IconButton
  //                           color="primary"
  //                           onClick={handleSavePhoneNumber}
  //                         >
  //                           <SaveIcon />
  //                         </IconButton>
  //                       ) : (
  //                         <IconButton
  //                           color="secondary"
  //                           onClick={() =>
  //                             handleEditPhoneNumber(phone.id, phone.phoneNumber)
  //                           }
  //                         >
  //                           <EditIcon />
  //                         </IconButton>
  //                       )}
  //                       <IconButton
  //                         color="error"
  //                         onClick={() => handleDeletePhoneNumber(phone.id)}
  //                       >
  //                         <DeleteIcon />
  //                       </IconButton>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))}
  //               </TableBody>
  //             </Table>
  //           </TableContainer>
  //         ) : (
  //           <Typography color="textSecondary" sx={{ mt: 2 }}>
  //             No phone number available
  //           </Typography>
  //         )}

  //         {/* Add New Phone Number Section */}
  //         <Box sx={{ mt: 2 }}>
  //           <TextField
  //             label="New Phone Number"
  //             fullWidth
  //             value={newPhoneNumber}
  //             onChange={(e) => setNewPhoneNumber(e.target.value)}
  //             sx={{ my: 1.5, fontSize: 16, borderRadius: 2 }}
  //           />
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             size="large"
  //             onClick={handleAddPhoneNumber}
  //             disabled={!newPhoneNumber.trim()}
  //           >
  //             Add Phone Number
  //           </Button>
  //         </Box>
  //       </Box>
  //     )}
  //   </Box>
  // );


  return (
    <Box
      p={4}
      sx={{
        maxWidth: 800,
        mx: "auto",
        bgcolor: "background.paper",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h6" gutterBottom fontWeight={600} textAlign="start">
        Select Alert Status
      </Typography>

      {/* Dropdown List */}
      <Select
        value={selectedItem}
        onChange={handleSelect}
        displayEmpty
        fullWidth
        sx={{ my: 1, py: 0, fontSize: 16, borderRadius: 2 }}
      >
        <MenuItem value="" disabled>
          Select an alert status
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.statusName}
          </MenuItem>
        ))}
      </Select>

      {/* Message Input Section */}
      {selectedItem && (
        <Box sx={{ mt: 1 }}>
          <Typography variant="h6" fontWeight={500}>
            Message
          </Typography>
          <TextField
            label="Message"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ my: 1, fontSize: 16, borderRadius: 2, pt: 0 }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleUpdateMessage}
          >
            Update Message
          </Button>
        </Box>
      )}

      {/* Phone Numbers Section */}
      {selectedItem && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" fontWeight={500}>
            Phone Numbers
          </Typography>

          {selectedPhoneNumbers.length > 0 ? (
            <TableContainer component={Paper} sx={{ mt: 1, borderRadius: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>Phone Number</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        textAlign: "center",
                        width: "120px",
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPhoneNumbers.map((phone) => (
                    <TableRow key={phone.id}>
                      <TableCell>
                        {editId === phone.id ? (
                          <TextField
                            value={editPhoneNumber}
                            onChange={(e) => setEditPhoneNumber(e.target.value)}
                            fullWidth
                            sx={{ py: 0, fontSize: 16, borderRadius: 2 }}
                          />
                        ) : (
                          phone.phoneNumber
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center", width: "120px" }}>
                        {editId === phone.id ? (
                          <IconButton
                            color="primary"
                            onClick={handleSavePhoneNumber}
                          >
                            <SaveIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              handleEditPhoneNumber(phone.id, phone.phoneNumber)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        <IconButton
                          color="error"
                          onClick={() => handleDeletePhoneNumber(phone.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography color="textSecondary" sx={{ mt: 2 }}>
              No phone number available
            </Typography>
          )}

          {/* Add New Phone Number Section */}
          <Box sx={{ mt: 2 }}>
            <TextField
              label="New Phone Number"
              fullWidth
              value={newPhoneNumber}
              onChange={(e) => setNewPhoneNumber(e.target.value)}
              sx={{ my: 1, fontSize: 16, borderRadius: 2, pt: 0 }}
            />
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAddPhoneNumber}
              disabled={!newPhoneNumber.trim()}
            >
              Add Phone Number
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );



};

export default AlertSMS;
