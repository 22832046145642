import React from "react";
import { Container, Paper, Typography } from "@mui/material";
// import Navbar from "./Navbar";

const Dashboard = () => {
  return (
    <Container>
      <Paper sx={{ p: 3, mt: "10px" }}>
        <Typography variant="h4">Dashboard Management</Typography>
        <Typography variant="body1">Manage users here.</Typography>
      </Paper>
    </Container>
  );
};

export default Dashboard;

