import React from "react";
// import httpClient from "../../../../https/httpClient";
import { toast } from "react-toastify";
import _ from "lodash";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";

const ProductionTargetConfig = () => {
  const [formData, setFormData] = React.useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = React.useState<{ [key: number]: string }>({});
  const [mainLineWeeklyTarget, setMainLineWeeklyTarget] = React.useState(0);
  const [crvLineWeeklyTarget, setCRVLineWeeklyTarget] = React.useState(0);
  const axiosPrivate = useAxiosPrivate();

  const fieldConfig: { [key: string]: { label: string; type: string } } = {
    // startDate: { label: "Start Date", type: "date" },
    monday: { label: "Monday", type: "number" },
    tuesday: { label: "Tuesday", type: "number" },
    wednesday: { label: "Wednesday", type: "number" },
    thursday: { label: "Thursday", type: "number" },
    friday: { label: "Friday", type: "number" },
  };

  const calculateWeeklyTargets = (setting: any) => {
    return (
      (setting?.monday || 0) +
      (setting?.tuesday || 0) +
      (setting?.wednesday || 0) +
      (setting?.thursday || 0) +
      (setting?.friday || 0)
    );
  };

  const fetchProductionReviewSetting = async () => {
    try {
      const productionReviewSettingResponse = await axiosPrivate.get(
        `get_all_production_target_configs`
      );
      const data = productionReviewSettingResponse.data;
      const formattedData = data.map((item: any) => ({
        ...item,
        // startDate: item.startDate ? item.startDate.split("T")[0] : "", // Format date correctly
      }));
      setFormData(formattedData);
      let mainLineWeeklyTargets = calculateWeeklyTargets(
        _.find(formattedData, { line: "main" })
      );
      let crvLineWeeklyTargets = calculateWeeklyTargets(
        _.find(formattedData, { line: "crv" })
      );
      setMainLineWeeklyTarget(mainLineWeeklyTargets);
      setCRVLineWeeklyTarget(crvLineWeeklyTargets);
    } catch (error) {
      console.log("Something is wrong", error);
    }
  };

  const handleChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setFormData((prevData: any[]) => {
      const newData = [...prevData];
      newData[index][name] = value;
      return newData;
    });

    // Validate input
    if (Number(value) < 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [index]: `${fieldConfig[name].label} cannot be negative`,
      }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[index];
        return newErrors;
      });
    }
  };

  const submitData = async (event: any) => {
    event.preventDefault();
    toast.info("Request pending...", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      toastId: "requestPending",
    });
    let hasError = false;
    formData.forEach((item, index) => {
      Object.keys(item).forEach((key) => {
        if (fieldConfig[key] && Number(item[key]) < 0) {
          hasError = true;
          setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: `${fieldConfig[key].label} cannot be negative`,
          }));
        }
      });
    });

    if (hasError) {
      toast.dismiss("requestPending"); // Close the request pending toast
      toast.error("Invalid input. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    try {
      await Promise.all(
        formData.map(async (item) => {
          const res = await axiosPrivate.put(
            `update_production_target_config/${item.id}`,
            item
          );
          if (res.data.isSuccess) {
            await axiosPrivate.post(`update_chassis_preparation_queue_report`);
          }
        })
      );
      toast.dismiss("requestPending");
      await fetchProductionReviewSetting();
      toast.success("File saved successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Failed to save data", error);
      toast.dismiss("requestPending");
      toast.error("Failed to save data...!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  React.useEffect(() => {
    fetchProductionReviewSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-center">
        <div className="form_wrapper">
          <form onSubmit={submitData}>
            <div className="row">
              {formData?.map((item: any, index: any) => (
                <div className="col-6" key={item.id}>
                  <div className="form-group px-5">
                    <h3>{item.line.toUpperCase()} Line</h3>
                    {item.line === "main" ? (
                      <>
                        <div className="mt-3">
                          <label className="fw-bold">
                            Production Target Per Week:
                          </label>
                          <p className="fw-bold">{mainLineWeeklyTarget}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="mt-3">
                          <label className="fw-bold">
                            Production Target Per Week:
                          </label>
                          <p className="fw-bold">{crvLineWeeklyTarget}</p>
                        </div>
                      </>
                    )}
                    {Object.keys(item)?.map(
                      (key) =>
                        key !== "id" &&
                        key !== "line" && (
                          <div key={key} className="mb-3">
                            <label htmlFor={`${key}-${index}`}>
                              {fieldConfig[key]?.label || key}
                            </label>
                            <input
                              type={fieldConfig[key]?.type || "text"}
                              className="form-control"
                              id={`${key}-${index}`}
                              name={key}
                              value={item[key] || ""}
                              onChange={(event) => handleChange(index, event)}
                            />
                          </div>
                        )
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="col-6 mx-auto mt-3">
              <input className="btn blue-btn" type="submit" value="Save" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductionTargetConfig;
