import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CycleTimer from "./CycleTImer";

function ProductionLineData(props: any) {
  const { prodLineData, lineName } = props;
  const getBackgroundColor = (stationAlert: any) => {
    const colorMap: Record<string, string> = {
      Supervisor: "#FF0000",
      "Work Active": "#FEB124",
      "Work Complete": "rgb(13 222 13)",
      Stores: "#0000FF",
      Planning: "#000000",
    };
    return colorMap[stationAlert] || "inherit";
  };
  return (
    <TableContainer component={Paper} elevation={2}>
      <Table size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "rgb(255 253 249)" }}>
            <TableCell
              colSpan={prodLineData.length + 1}
              className="fw-bold"
              style={{ fontSize: "1.6vmin" }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {lineName} ASSEMBLY
                {lineName === "CRV LINE" && <CycleTimer line="crv" />}
                {lineName === "MAIN LINE" && <CycleTimer line="main" />}
              </span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prodLineData.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={1}
                style={{ textAlign: "center", fontSize: "1.4vmin" }}
              >
                No data available
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableRow style={{ backgroundColor: "#f0f8ff" }}>
                <TableCell style={{ fontSize: "1.4vmin" }} className="py-0">
                  Station #
                </TableCell>
                {prodLineData.map((station: any, index: any) => (
                  <TableCell
                    key={index}
                    style={{ fontSize: "1.6vmin", textAlign: "center" }}
                    className="py-0"
                  >
                    {station.lineNumber}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell style={{ fontSize: "1.4vmin" }} className="py-0">
                  Chassis No
                </TableCell>
                {prodLineData.map((station: any, index: any) => (
                  <TableCell
                    key={index}
                    style={{ fontSize: "1.6vmin", textAlign: "center" }}
                    className="py-0"
                  >
                    {station.priorityNo}
                    {station.priorityNo ? ")" : ""}
                    {station.chassisNo}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell className="py-0" style={{ fontSize: "1.4vmin" }}>
                  Status
                </TableCell>
                {prodLineData.map((station: any, index: any) => (
                  <TableCell
                    key={index}
                    style={{ textAlign: "center" }}
                    className="py-0 pt-1"
                  >
                    <span
                      style={{
                        backgroundColor: getBackgroundColor(
                          station.stationAlert
                        ),
                        padding: "7px 30px", // Add padding to make the background visible
                        // borderRadius: "4px", // Optional: to make it look better
                        display: "inline-block", // Keeps it within the content area
                        lineHeight: 1
                      }}
                    >
                      
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ProductionLineData;
