import {
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";

export default function ScheduledChassisForCNC() {
  const axiosPrivate = useAxiosPrivate();
  const [scheduledChassisDataForMain, setScheduledChassisDataForMain] =
    useState<any>([]);
  const [scheduledChassisDataForCrv, setScheduledChassisDataForCrv] =
    useState<any>([]);

  // const fetchScheduledChassisData = async (line: string) => {
  //   try {
  //     const response = await axiosPrivate.get(
  //       `get_scheduled_chassis_queues_by_line/${line}`
  //     );
  //     let scheduledChassisQueues = response.data;

  //     const missingItems = 20 - scheduledChassisQueues.length;
  //     const generatedItems = Array.from({ length: missingItems }, () => ({
  //       id: null,
  //       chassisNo: "",
  //       model: "",
  //       dealer: "",
  //       notes: "",
  //       priorityNo: "",
  //       duplicate: null,
  //     }));
  //     if (line === "main") {
  //       setScheduledChassisDataForMain([
  //         ...scheduledChassisQueues,
  //         ...generatedItems,
  //       ]);
  //     } else {
  //       setScheduledChassisDataForMain([
  //         ...scheduledChassisQueues,
  //         ...generatedItems,
  //       ]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching scheduled chassis data:", error);
  //   }
  // };

  const fetchScheduledChassisData = async (line: string) => {
    try {
      const response = await axiosPrivate.get(
        `get_scheduled_chassis_queues_by_line/${line}`
      );
      let scheduledChassisQueues = response.data;
      if (line === "main") {
        setScheduledChassisDataForMain([...scheduledChassisQueues]);
      } else {
        setScheduledChassisDataForCrv([...scheduledChassisQueues]);
      }
    } catch (error) {
      console.error("Error fetching scheduled chassis data:", error);
    }
  };

  useEffect(() => {
    fetchScheduledChassisData("main");
    fetchScheduledChassisData("crv");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // return (
  //   <TableContainer
  //     className="p-0"
  //     component={Paper}
  //     style={{ marginTop: "8px", height: "fit-content", minWidth: "100%" }}
  //   >
  //     {scheduledChassisDataForMain.length > 0 ? (
  //       <Table size="small" className="p-0">
  //         <TableHead>
  //           <TableRow>
  //             <TableCell
  //               style={{ width: "90px", backgroundColor: "rgb(59, 17, 80)" }}
  //             >
  //               <Typography
  //                 variant="subtitle1"
  //                 style={{
  //                   color: "white",
  //                   fontFamily: "League Spartan, sans-serif",
  //                 }}
  //               >
  //                 Priority No
  //               </Typography>
  //             </TableCell>
  //             <TableCell
  //               style={{ width: "90px", backgroundColor: "rgb(59, 17, 80)" }}
  //             >
  //               <Typography
  //                 variant="subtitle1"
  //                 style={{
  //                   color: "white",
  //                   fontFamily: "League Spartan, sans-serif",
  //                 }}
  //               >
  //                 Chassis No
  //               </Typography>
  //             </TableCell>
  //             <TableCell
  //               style={{ width: "90px", backgroundColor: "rgb(59, 17, 80)" }}
  //             >
  //               <Typography
  //                 variant="subtitle1"
  //                 style={{
  //                   color: "white",
  //                   fontFamily: "League Spartan, sans-serif",
  //                 }}
  //               >
  //                 Model
  //               </Typography>
  //             </TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {scheduledChassisDataForMain.map((item: any, index: number) => (
  //             <TableRow key={index}>
  //               <TableCell>
  //                 <Typography>{item.priorityNo}</Typography>
  //               </TableCell>
  //               <TableCell>
  //                 <Typography>{item.chassisNo}</Typography>
  //               </TableCell>
  //               <TableCell>
  //                 <Typography>{item.model}</Typography>
  //               </TableCell>
  //             </TableRow>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     ) : (
  //       <Typography
  //         variant="body1"
  //         style={{
  //           marginTop: "8px",
  //           paddingLeft: "5px",
  //           fontFamily: "League Spartan, sans-serif",
  //         }}
  //       >
  //         No data found..!
  //       </Typography>
  //     )}
  //   </TableContainer>
  // );
  return (
    <Grid
      container
      spacing={2}
      style={{
        marginTop: "50px",
        // boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)", // Added box shadow
        //  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        //  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        padding: "10px", // Optional: Adds space inside the box
        borderRadius: "8px", // Optional: Rounds the corners for a smoother look
        backgroundColor: "white", // Ensures background visibility with shadow
      }}
    >
      {/* First Table */}
      <Grid item xs={6}>
        <Typography style={{ display: "flex", justifyContent: "start" }}>
          <h6>Next five JOBs of Main Line</h6>
        </Typography>
        <TableContainer
          className="p-0"
          component={Paper}
          style={{
            height: "fit-content",
            minWidth: "100%",
            border: "1px solid #d1d1d1",
          }}
        >
          {scheduledChassisDataForMain.length > 0 ? (
            <Table size="small" className="p-0">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: "25%",
                      // backgroundColor: "rgb(59, 17, 80)",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      Priority No
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "25%",
                      // backgroundColor: "rgb(59, 17, 80)",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      Chassis No
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "50%",
                      // backgroundColor: "rgb(59, 17, 80)",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      Model
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scheduledChassisDataForMain.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{item.priorityNo}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.chassisNo}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.model}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography
              variant="body1"
              style={{
                marginTop: "8px",
                paddingLeft: "5px",
                fontFamily: "League Spartan, sans-serif",
              }}
            >
              No data found..!
            </Typography>
          )}
        </TableContainer>
      </Grid>

      {/* Second Table */}
      <Grid item xs={6}>
        <Typography style={{ alignItems: "start" }}>
          <h6>Next five JOBs of CRV Line</h6>
        </Typography>

        <TableContainer
          className="p-0"
          component={Paper}
          style={{
            height: "fit-content",
            minWidth: "100%",
            border: "1px solid #d1d1d1",
          }}
        >
          {scheduledChassisDataForCrv.length > 0 ? (
            <Table size="small" className="p-0">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: "25%",
                      // backgroundColor: "rgb(59, 17, 80)",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      Priority No
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "25%",
                      // backgroundColor: "rgb(59, 17, 80)",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      Chassis No
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      width: "50%",
                      // backgroundColor: "rgb(59, 17, 80)",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#000000",
                        fontWeight: "700",
                        fontFamily: "League Spartan, sans-serif",
                      }}
                    >
                      Model
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scheduledChassisDataForCrv.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{item.priorityNo}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.chassisNo}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item.model}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography
              variant="body1"
              style={{
                marginTop: "8px",
                paddingLeft: "5px",
                fontFamily: "League Spartan, sans-serif",
              }}
            >
              No data found..!
            </Typography>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
}
