// import {
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import React from "react";

// function FinalReports() {
//   //   const getBackgroundColor = (stationAlert: any) => {
//   //     const colorMap: Record<string, string> = {
//   //       Supervisor: "#FF0000",
//   //       Stores: "#0000FF",
//   //       Planning: "#000000",
//   //     };
//   //     return colorMap[stationAlert] || "inherit";
//   //   };

//   //   const MAX_COLUMNS_PER_ROW = 18;

//   // Helper function to split the data into chunks
//   //   const chunkArray = (arr: any[], size: number) => {
//   //     return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
//   //       arr.slice(index * size, index * size + size)
//   //     );
//   //   };

//   return (
//     // <TableContainer
//     //   component={Paper}
//     //   elevation={2}
//     //   style={{ marginTop: "10px" }}
//     // >
//     //   <Table size="small" style={{ tableLayout: "fixed", width: "100%" }}>
//     //     <TableHead>
//     //       <TableRow style={{ backgroundColor: "rgb(255 253 249)", width: "100%" }}>
//     //         <TableCell
//     //           colSpan={12}
//     //           className="fw-bold py-0"
//     //           style={{ fontSize: "1.6vmin" }}
//     //         >
//     //           GP12
//     //         </TableCell>
//     //       </TableRow>
//     //     </TableHead>
//     //     <TableBody>
//     //       <React.Fragment>
//     //         <TableRow>
//     //           <TableCell
//     //             style={{
//     //               fontSize: "1.3vmin",
//     //               width: "130px",
//     //               padding: "2px 10px",
//     //             }}
//     //           >
//     //             Final Detail
//     //           </TableCell>
//     //           <TableCell
//     //             style={{
//     //               fontSize: "1.3vmin",
//     //               textAlign: "center",
//     //               //   width: "100px",
//     //               padding: "2px 10px",
//     //             }}
//     //           ></TableCell>
//     //         </TableRow>

//     //         <TableRow>
//     //           <TableCell
//     //             style={{
//     //               fontSize: "1.3vmin",
//     //               width: "130px",
//     //               padding: "1px 10px",
//     //             }}
//     //           >
//     //             Compliance
//     //           </TableCell>
//     //           <TableCell
//     //             style={{
//     //               fontSize: "1.3vmin",
//     //               textAlign: "center",
//     //               //   backgroundColor: getBackgroundColor(item.alertType),
//     //               color: "#ffffff",
//     //               //   width: "100px",
//     //               padding: "1px 10px",
//     //             }}
//     //           >
//     //             {/* {item.alertType} */}
//     //           </TableCell>
//     //         </TableRow>

//     //         <TableRow>
//     //           <TableCell
//     //             style={{
//     //               fontSize: "1.3vmin",
//     //               width: "130px",
//     //               padding: "1px 10px",
//     //             }}
//     //           >
//     //             Final Inspection
//     //           </TableCell>
//     //           <TableCell
//     //             style={{
//     //               fontSize: "1.3vmin",
//     //               textAlign: "center",
//     //               padding: "1px 10px",
//     //               //   width: "100px",
//     //             }}
//     //           ></TableCell>
//     //         </TableRow>
//     //       </React.Fragment>
//     //     </TableBody>
//     //   </Table>
//     // </TableContainer>
//     <TableContainer
//       component={Paper}
//       elevation={2}
//       style={{ marginTop: "10px" }}
//     >
//       <Table size="small" style={{ width: "100%" }}>
//         {" "}
//         {/* Remove tableLayout: "fixed" */}
//         <TableHead>
//           <TableRow style={{ backgroundColor: "rgb(255 253 249)" }}>
//             <TableCell
//               colSpan={3}
//               className="fw-bold py-0"
//               style={{ fontSize: "1.6vmin" }}
//             >
//               GP12
//             </TableCell>
//           </TableRow>
//           <TableRow style={{ backgroundColor: "#f5f5f5" }}>
//             <TableCell
//               className="py-0 my-0"
//               style={{
//                 fontSize: "1.3vmin",
//                 fontWeight: "bold",
//                 whiteSpace: "nowrap",
//                 width: "auto",
//                 lineHeight: 1
//               }}
//             >
//               {/* Criteria */}
//             </TableCell>
//             <TableCell
//               className="py-0 my-0"
//               style={{ fontSize: "1.3vmin", textAlign: "center", width: "50%", fontWeight: 600, lineHeight: 1 }}
//             >
//               MAIN
//             </TableCell>
//             <TableCell
//               className="py-0 my-0"
//               style={{ fontSize: "1.3vmin", textAlign: "center", width: "50%", fontWeight: 600, lineHeight: 1 }}
//             >
//               CRV
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {["Final Detail", "Compliance", "Final Inspection"].map(
//             (label, index) => (
//               <TableRow key={index}>
//                 <TableCell
//                   style={{
//                     fontSize: "1.3vmin",
//                     padding: "2px 10px",
//                     whiteSpace: "nowrap", // Prevents wrapping
//                     width: "auto", // Allow dynamic width
//                   }}
//                 >
//                   {label}
//                 </TableCell>
//                 <TableCell
//                   style={{
//                     fontSize: "1.3vmin",
//                     textAlign: "center",
//                     padding: "2px 10px",
//                   }}
//                 >
//                   {/* MAIN Value */}
//                 </TableCell>
//                 <TableCell
//                   style={{
//                     fontSize: "1.3vmin",
//                     textAlign: "center",
//                     padding: "2px 10px",
//                   }}
//                 >
//                   {/* CRV Value */}
//                 </TableCell>
//               </TableRow>
//             )
//           )}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }
// export default FinalReports;

// import React, { useEffect, useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";
// import useAxiosPrivate from "../../common/useAxiosPrivate";

// interface ChassisData {
//   chassisNo?: string;
//   model?: string;
//   priorityNo?: string;
//   stationAlert?: string;
//   notes?: string;
//   line?: string; // Assuming this field determines MAIN or CRV
// }

// const FinalReports: React.FC = () => {
//   const axiosPrivate = useAxiosPrivate();

//   const fetchFinalDetail = async (): Promise<ChassisData[]> => {
//     const res = await axiosPrivate.get("get_all_final_detail_list");
//     return res.data ?? [];
//   };

//   const fetchCompliance = async (): Promise<ChassisData[]> => {
//     const response = await axiosPrivate.get("get_all_compliance_queue_list");
//     return response.data ?? [];
//   };

//   const fetchFinalInspection = async (): Promise<ChassisData[]> => {
//     const response = await axiosPrivate.get(
//       "get_all_final_inspection_queue_list"
//     );
//     return response.data ?? [];
//   };

//   const [finalDetail, setFinalDetail] = useState<ChassisData[]>([]);
//   const [compliance, setCompliance] = useState<ChassisData[]>([]);
//   const [finalInspection, setFinalInspection] = useState<ChassisData[]>([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const [fd, comp, fi] = await Promise.all([
//           fetchFinalDetail(),
//           fetchCompliance(),
//           fetchFinalInspection(),
//         ]);
//         setFinalDetail(fd);
//         setCompliance(comp);
//         setFinalInspection(fi);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   /**
//    * Function to separate MAIN and CRV data
//    * Assuming `line` field determines it (replace condition if needed)
//    */
//   const separateData = (data: ChassisData[]) => {
//     const mainData = data.filter((item) => item.line === "MAIN").slice(0, 3);
//     const crvData = data.filter((item) => item.line === "CRV").slice(0, 3);
//     return { mainData, crvData };
//   };

//   return (
//     <TableContainer
//       component={Paper}
//     >
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell
//               colSpan={7}
//               align="center"
//               sx={{ fontWeight: "bold", fontSize: 16 }}
//             >
//               GP12
//             </TableCell>
//           </TableRow>
//           {/* <TableRow>
//             <TableCell sx={{ fontWeight: "bold" }}></TableCell>
//             <TableCell
//               colSpan={3}
//               sx={{ fontWeight: "bold", textAlign: "center" }}
//             >
//               MAIN
//             </TableCell>
//             <TableCell
//               colSpan={3}
//               sx={{ fontWeight: "bold", textAlign: "center" }}
//             >
//               CRV
//             </TableCell>
//           </TableRow> */}
//         </TableHead>
//         <TableBody>
//           {[
//             { label: "Final Detail", data: finalDetail },
//             { label: "Compliance", data: compliance },
//             { label: "Final Inspection", data: finalInspection },
//           ].map((section, index) => {
//             const { mainData, crvData } = separateData(section.data);
//             return (
//               <TableRow key={index}>
//                 <TableCell sx={{ fontWeight: "bold" }}>
//                   {section.label}
//                 </TableCell>
//                 {mainData.map((item, i) => (
//                   <TableCell key={i}>{item.chassisNo || "N/A"}</TableCell>
//                 ))}
//                 {Array(3 - mainData.length)
//                   .fill("N/A")
//                   .map((_, i) => (
//                     <TableCell key={`main-empty-${i}`}>N/A</TableCell>
//                   ))}
//                 {crvData.map((item, i) => (
//                   <TableCell key={i}>{item.chassisNo || "N/A"}</TableCell>
//                 ))}
//                 {Array(3 - crvData.length)
//                   .fill("N/A")
//                   .map((_, i) => (
//                     <TableCell key={`crv-empty-${i}`}>N/A</TableCell>
//                   ))}
//               </TableRow>
//             );
//           })}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default FinalReports;

// import React from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
// } from "@mui/material";

// // Define the data type
// interface DataItem {
//   id: string;
//   chassisNo: string;
//   model: string;
//   priorityNo: string;
//   stationAlert: string | null;
//   notes: string | null;
//   line: "main" | "crv";
//   createdOn: string;
// }

// // Sample Data
// const data: DataItem[] = [
//   {
//     id: "67c141db6557ce6dc08b8607",
//     chassisNo: "15764",
//     model: "Musketeer Recliner MY25",
//     priorityNo: "6",
//     stationAlert: "",
//     notes: "test",
//     line: "main",
//     createdOn: "2025-02-28T04:55:55.906000",
//   },
//   {
//     id: "67c156597cf34cde5393da88",
//     chassisNo: "14837",
//     model: "CRV Esperance MY25",
//     priorityNo: "2",
//     stationAlert: null,
//     notes: null,
//     line: "crv",
//     createdOn: "2025-02-28T06:23:21.227000",
//   },
//   {
//     id: "67c5532b4fb43dbc2b5dd8b5",
//     chassisNo: "15770",
//     model: "Excalibur Prince MY25",
//     priorityNo: "7",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T06:58:51.608000",
//   },
//   {
//     id: "67c5541b4fb43dbc2b5dd8b6",
//     chassisNo: "14520",
//     model: "Lifechanger Bunkie MY25",
//     priorityNo: "8",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T07:02:51.927000",
//   },
//   {
//     id: "67c5550c4fb43dbc2b5dd8b7",
//     chassisNo: "15881",
//     model: "Lifechanger Pacific MY25",
//     priorityNo: "9",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T07:06:52.551000",
//   },
//   {
//     id: "67c556ef4fb43dbc2b5dd8ba",
//     chassisNo: "14489",
//     model: "Lifechanger Bunkie MY24",
//     priorityNo: "10",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T07:14:55.575000",
//   },
// ];

// // Function to extract chassis numbers based on line
// const getChassisNumbers = (line: "main" | "crv") => {
//   return data
//     .filter((item) => item.line === line)
//     .map((item) => item.chassisNo);
// };

// const FinalReports: React.FC = () => {
//   const mainChassisNumbers = getChassisNumbers("main");
//   const crvChassisNumbers = getChassisNumbers("crv");

//   return (
//     <TableContainer
//       component={Paper}
//       sx={{ maxWidth: 800, margin: "auto", mt: 4 }}
//     >
//       <Typography
//         variant="h6"
//         sx={{ textAlign: "left", fontWeight: "bold", padding: 2 }}
//       >
//         GP12
//       </Typography>
//       <Table>
//         {/* Table Head */}
//         <TableHead>
//           <TableRow>
//             <TableCell></TableCell>
//             <TableCell
//               align="center"
//               colSpan={mainChassisNumbers.length}
//               sx={{ fontWeight: "bold" }}
//             >
//               MAIN
//             </TableCell>
//             <TableCell
//               align="center"
//               colSpan={crvChassisNumbers.length}
//               sx={{ fontWeight: "bold" }}
//             >
//               CRV
//             </TableCell>
//           </TableRow>
//         </TableHead>

//         {/* Table Body */}
//         <TableBody>
//           {["Final Detail", "Compliance", "Final Inspection"].map((label) => (
//             <TableRow key={label}>
//               <TableCell sx={{ fontWeight: "bold" }}>{label}</TableCell>
//               {/* MAIN Chassis Numbers */}
//               {mainChassisNumbers.map((chassis, index) => (
//                 <TableCell key={`main-${index}`} align="center">
//                   {chassis}
//                 </TableCell>
//               ))}
//               {/* CRV Chassis Numbers */}
//               {crvChassisNumbers.map((chassis, index) => (
//                 <TableCell key={`crv-${index}`} align="center">
//                   {chassis}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default FinalReports;

// import React, { useState } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
// } from "@mui/material";
// import useAxiosPrivate from "../../common/useAxiosPrivate";

// // Define the data type
// interface DataItem {
//   id: string;
//   chassisNo: string;
//   model: string;
//   priorityNo: string;
//   stationAlert: string | null;
//   notes: string | null;
//   line: "main" | "crv";
//   createdOn: string;
// }

// // Sample Data
// const data: DataItem[] = [
//   {
//     id: "67c141db6557ce6dc08b8607",
//     chassisNo: "15764",
//     model: "Musketeer Recliner MY25",
//     priorityNo: "6",
//     stationAlert: "",
//     notes: "test",
//     line: "main",
//     createdOn: "2025-02-28T04:55:55.906000",
//   },
//   {
//     id: "67c156597cf34cde5393da88",
//     chassisNo: "14837",
//     model: "CRV Esperance MY25",
//     priorityNo: "2",
//     stationAlert: null,
//     notes: null,
//     line: "crv",
//     createdOn: "2025-02-28T06:23:21.227000",
//   },
//   {
//     id: "67c156597cf34cde5393da88",
//     chassisNo: "14837",
//     model: "CRV Esperance MY25",
//     priorityNo: "2",
//     stationAlert: null,
//     notes: null,
//     line: "crv",
//     createdOn: "2025-02-28T06:23:21.227000",
//   },
//   {
//     id: "67c5532b4fb43dbc2b5dd8b5",
//     chassisNo: "15770",
//     model: "Excalibur Prince MY25",
//     priorityNo: "7",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T06:58:51.608000",
//   },
//   {
//     id: "67c5541b4fb43dbc2b5dd8b6",
//     chassisNo: "14520",
//     model: "Lifechanger Bunkie MY25",
//     priorityNo: "8",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T07:02:51.927000",
//   },
//   {
//     id: "67c5550c4fb43dbc2b5dd8b7",
//     chassisNo: "15881",
//     model: "Lifechanger Pacific MY25",
//     priorityNo: "9",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T07:06:52.551000",
//   },
//   {
//     id: "67c556ef4fb43dbc2b5dd8ba",
//     chassisNo: "14489",
//     model: "Lifechanger Bunkie MY24",
//     priorityNo: "10",
//     stationAlert: null,
//     notes: null,
//     line: "main",
//     createdOn: "2025-03-03T07:14:55.575000",
//   },
// ];

// // Function to extract chassis numbers based on line
// const getChassisNumbers = (line: "main" | "crv") => {
//   return data
//     .filter((item) => item.line === line)
//     .map((item) => item.chassisNo);
// };

// const GP12Table: React.FC = () => {
//   const axiosPrivate = useAxiosPrivate();
//   const [finalDetailData, setFinalDetailData] = useState<DataItem[]>([]);
//   const [complianceData, setComplianceData] = useState<DataItem[]>([]);
//   const [finalInspectionData, setFinalInspectionData] = useState<DataItem[]>(
//     []
//   );

//   const fetchFinalDetailData = async () => {
//     try {
//       const res = await axiosPrivate.get("get_all_final_detail_list");
//       setFinalDetailData(res.data);
//     } catch (error) {
//       console.error("Error fetching alert names:", error);
//     }
//   };
//   const fetchComplianceData = async () => {
//     try {
//       const res = await axiosPrivate.get("get_all_compliance_queue_list");
//       setComplianceData(res.data);
//     } catch (error) {
//       console.error("Error fetching alert names:", error);
//     }
//   };
//   const fetchFinalInspectionQueueData = async () => {
//     try {
//       const res = await axiosPrivate.get("get_all_final_inspection_queue_list");
//       setFinalInspectionData(res.data);
//     } catch (error) {
//       console.error("Error fetching alert names:", error);
//     }
//   };
//   const mainChassisNumbers = getChassisNumbers("main");
//   const crvChassisNumbers = getChassisNumbers("crv");

//   return (
//     <TableContainer component={Paper}>
//       <Typography
//         variant="h6"
//         sx={{ textAlign: "left", fontWeight: "bold", border: "1px solid" }}
//       >
//         GP12
//       </Typography>
//       <Table>
//         {/* Table Head */}
//         <TableHead>
//           <TableRow>
//             <TableCell></TableCell>
//             <TableCell
//               className="py-0 my-0"
//               align="center"
//               colSpan={mainChassisNumbers.length}
//               sx={{ fontWeight: "bold", borderRight: "2px solid black" }}
//             >
//               MAIN
//             </TableCell>
//             <TableCell
//               className="py-0 my-0"
//               align="center"
//               colSpan={crvChassisNumbers.length}
//               sx={{ fontWeight: "bold" }}
//             >
//               CRV
//             </TableCell>
//           </TableRow>
//         </TableHead>

//         {/* Table Body */}
//         <TableBody>
//           {["Final Detail", "Compliance", "Final Inspection"].map((label) => (
//             <TableRow key={label}>
//               <TableCell sx={{ fontWeight: "bold" }}>{label}</TableCell>
//               {/* MAIN Chassis Numbers with Border on Last Column */}
//               {mainChassisNumbers.map((chassis, index) => (
//                 <TableCell
//                   className="py-0 my-0"
//                   key={`main-${index}`}
//                   align="center"
//                   sx={
//                     index === mainChassisNumbers.length - 1
//                       ? { borderRight: "2px solid black" }
//                       : {}
//                   }
//                 >
//                   {chassis}
//                 </TableCell>
//               ))}
//               {/* CRV Chassis Numbers */}
//               {crvChassisNumbers.map((chassis, index) => (
//                 <TableCell
//                   className="py-0 my-0"
//                   key={`crv-${index}`}
//                   align="center"
//                 >
//                   {chassis}
//                 </TableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default GP12Table;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import useAxiosPrivate from "../../common/useAxiosPrivate";

// Define the data type
interface DataItem {
  id: string;
  chassisNo: string;
  model: string;
  priorityNo: string;
  stationAlert: string | null;
  notes: string | null;
  line: "main" | "crv";
  createdOn: string;
}

const GP12Table: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();

  // State for storing API data
  const [finalDetailData, setFinalDetailData] = useState<DataItem[]>([]);
  const [complianceData, setComplianceData] = useState<DataItem[]>([]);
  const [finalInspectionData, setFinalInspectionData] = useState<DataItem[]>(
    []
  );

  // Fetch API Data
  useEffect(() => {
    fetchFinalDetailData();
    fetchComplianceData();
    fetchFinalInspectionQueueData();

    let id = setInterval(() => {
      fetchFinalDetailData();
      fetchComplianceData();
      fetchFinalInspectionQueueData();
    }, 5 * 1000);
    return () => clearInterval(id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFinalDetailData = async () => {
    try {
      const res = await axiosPrivate.get("get_all_final_detail_list");
      setFinalDetailData(res.data);
    } catch (error) {
      console.error("Error fetching Final Detail data:", error);
    }
  };

  const fetchComplianceData = async () => {
    try {
      const res = await axiosPrivate.get("get_all_compliance_queue_list");
      setComplianceData(res.data);
    } catch (error) {
      console.error("Error fetching Compliance data:", error);
    }
  };

  const fetchFinalInspectionQueueData = async () => {
    try {
      const res = await axiosPrivate.get("get_all_final_inspection_queue_list");
      setFinalInspectionData(res.data);
    } catch (error) {
      console.error("Error fetching Final Inspection data:", error);
    }
  };

  // Function to extract chassis numbers based on line (MAIN or CRV)
  const getChassisNumbers = (data: DataItem[], line: "main" | "crv") => {
    return data
      .filter((item) => item.line === line)
      .map((item) => item.chassisNo);
  };

  // Extract chassis numbers for each category
  const finalDetailMain = getChassisNumbers(finalDetailData, "main");
  const finalDetailCRV = getChassisNumbers(finalDetailData, "crv");

  const complianceMain = getChassisNumbers(complianceData, "main");
  const complianceCRV = getChassisNumbers(complianceData, "crv");

  const finalInspectionMain = getChassisNumbers(finalInspectionData, "main");
  const finalInspectionCRV = getChassisNumbers(finalInspectionData, "crv");

  // Get max columns for proper alignment
  const maxMainColumns = Math.max(
    finalDetailMain.length,
    complianceMain.length,
    finalInspectionMain.length
  );
  const maxCRVColumns = Math.max(
    finalDetailCRV.length,
    complianceCRV.length,
    finalInspectionCRV.length
  );

  return (
    <TableContainer
      component={Paper}
      // sx={{ maxWidth: 800, margin: "auto", mt: 4 }}
    >
      {/* <Typography
        variant="h6"
        sx={{ textAlign: "left", fontWeight: "bold", border: "1px solid" }}
      >
        GP12
      </Typography> */}
      <Table>
        {/* Table Head */}
        <TableHead>
          <TableRow
            style={{ backgroundColor: "rgb(255 253 249)", width: "100%" }}
          >
            <TableCell
              colSpan={12}
              className="fw-bold py-0"
              style={{ fontSize: "1.6vmin" }}
            >
              GP12
            </TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: "#f5f5f5" }}>
            <TableCell
              className="py-0 my-0"
              sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              style={{
                fontSize: "1.3vmin",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                width: "auto",
                lineHeight: 1,
              }}
            ></TableCell>
            <TableCell
              // align="center"
              colSpan={maxMainColumns}
              sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              className="py-0 my-0"
              style={{
                fontSize: "1.3vmin",
                textAlign: "center",
                width: "50%",
                fontWeight: 600,
                lineHeight: 1,
              }}
            >
              MAIN
            </TableCell>
            <TableCell
              // align="center"
              colSpan={maxCRVColumns}
              // sx={{ fontWeight: "bold" }}
              className="py-0 my-0"
              style={{
                fontSize: "1.3vmin",
                textAlign: "center",
                width: "50%",
                fontWeight: 600,
                lineHeight: 1,
              }}
            >
              CRV
            </TableCell>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {[
            {
              label: "Final Detail",
              main: finalDetailMain,
              crv: finalDetailCRV,
            },
            { label: "Compliance", main: complianceMain, crv: complianceCRV },
            {
              label: "Final Inspection",
              main: finalInspectionMain,
              crv: finalInspectionCRV,
            },
          ].map((row) => (
            <TableRow key={row.label}>
              <TableCell
                sx={{ fontWeight: "bold" }}
                style={{
                  fontSize: "1.3vmin",
                  padding: "2px 10px",
                  whiteSpace: "nowrap", // Prevents wrapping
                  width: "auto", // Allow dynamic width
                }}
              >
                {row.label}
              </TableCell>

              {/* MAIN Chassis Numbers with Border on Last Column */}
              {row.main
                .concat(Array(maxMainColumns - row.main.length).fill(""))
                .map((chassis, index) => (
                  <TableCell
                    key={`main-${index}`}
                    align="center"
                    sx={
                      index === maxMainColumns - 1
                        ? { borderRight: "1px solid black" }
                        : {}
                    }
                    style={{
                      fontSize: "1.3vmin",
                      textAlign: "center",
                      padding: "2px 10px",
                    }}
                  >
                    {chassis}
                  </TableCell>
                ))}

              {/* CRV Chassis Numbers */}
              {row.crv
                .concat(Array(maxCRVColumns - row.crv.length).fill(""))
                .map((chassis, index) => (
                  <TableCell
                    style={{
                      fontSize: "1.3vmin",
                      textAlign: "center",
                      padding: "2px 10px",
                    }}
                    key={`crv-${index}`}
                    align="center"
                  >
                    {chassis}
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GP12Table;
