import React from "react";
import { Container, Paper, Typography } from "@mui/material";

const Users = () => {
  return (
    <Container>
      <Paper sx={{ p: 3, mt: 10 }}>
        <Typography variant="h4">Users Management</Typography>
        <Typography variant="body1">Manage users here.</Typography>
      </Paper>
    </Container>
  );
};

export default Users;
