import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AddAlert } from "@mui/icons-material";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const drawerWidth = 240;

  const menuItems = [
    { name: "Alert SMS", path: "/admin/alertsms", icon: <AddAlert /> },
    // { name: "Dashboard", path: "/admin/dashboard", icon: <Dashboard /> },
    // { name: "Users", path: "/admin/users", icon: <People /> },
    // { name: "Reports", path: "/admin/reports", icon: <BarChart /> },
    // { name: "Settings", path: "/admin/settings", icon: <Settings /> },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "rgb(59, 17, 80)",
          color: "white",
          zIndex: "auto",
        },
      }}
      // style={{ zIndex: "auto !important" }}
    >
      <Toolbar />
      {/* <List>
        <ListItem button onClick={() => navigate("/admin/dashboard")}>
          <ListItemIcon>
            <Dashboard sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button onClick={() => navigate("/admin/users")}>
          <ListItemIcon>
            <People sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </List> */}
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.name}
            onClick={() => navigate(item.path)}
            sx={{
              backgroundColor:
                location.pathname === item.path ? "#495057" : "transparent",
              "&:hover": { backgroundColor: "#6c757d" },
            }}
          >
            <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
        {/* Logout */}
        {/* <ListItem
          button
          onClick={() => navigate("/logout")}
          sx={{ "&:hover": { backgroundColor: "#d9534f" } }}
        >
          <ListItemIcon sx={{ color: "white" }}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem> */}
      </List>
    </Drawer>
  );
};

export default Sidebar;
