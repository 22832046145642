import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

function ActiveAlertsData(props: any) {
  const { activeAlertData } = props;
  const getBackgroundColor = (stationAlert: any) => {
    const colorMap: Record<string, string> = {
      Supervisor: "#FF0000",
      Stores: "#0000FF",
      Planning: "#000000",
    };
    return colorMap[stationAlert] || "inherit";
  };

  const MAX_COLUMNS_PER_ROW = 18; // Change this number based on how many columns you want per row

  // Helper function to split the data into chunks
  const chunkArray = (arr: any[], size: number) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, index * size + size)
    );
  };
  const legends = [
    { label: "Supervisor", color: "red", border: "1px solid black" },
    { label: "Work Active", color: "#FEB124", border: "1px solid black" },
    {
      label: "Work Complete",
      color: "rgb(13 222 13)",
      border: "1px solid black",
    },
    { label: "Stores", color: "blue", border: "1px solid black" },
    { label: "Planning", color: "#000000", border: "1px solid black" },
  ];
  return (
    // <TableContainer
    //   component={Paper}
    //   elevation={2}
    //   style={{ marginTop: "10px" }}
    // >
    //   <Table size="small" style={{ tableLayout: "fixed", width: "100%" }}>
    //     <TableHead>
    //       <TableRow style={{ backgroundColor: "rgb(255 253 249)" }}>
    //         <TableCell
    //           colSpan={activeAlertData.length + 1}
    //           className="fw-bold"
    //           style={{ width: "300px", fontSize: "1.6vmin" }}
    //         >
    //           <span>ACTIVE ALERTS</span>
    //         </TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       <TableRow>
    //         <TableCell style={{ fontSize: "1.3vmin", width: "100px" }}>
    //           Station/Section
    //         </TableCell>
    //         {activeAlertData.map((item: any, index: any) => (
    //           <TableCell
    //             key={index}
    //             style={{
    //               fontSize: "1.3vmin",
    //               textAlign: "center",
    //               width: "100px",
    //             }}
    //           >
    //             {item.stationOrSection}
    //           </TableCell>
    //         ))}
    //       </TableRow>
    //       <TableRow>
    //         <TableCell style={{ fontSize: "1.3vmin", width: "100px" }}>
    //           Alert Type
    //         </TableCell>
    //         {activeAlertData.map((item: any, index: any) => (
    //           <TableCell
    //             key={index}
    //             style={{
    //               fontSize: "1.3vmin",
    //               textAlign: "center",
    //               backgroundColor: getBackgroundColor(item.alertType),
    //               color: "#ffffff",
    //               width: "100px",
    //             }}
    //           >
    //             {item.alertType}
    //           </TableCell>
    //         ))}
    //       </TableRow>
    //       <TableRow>
    //         <TableCell style={{ fontSize: "1.3vmin", width: "100px" }}>
    //           Notes
    //         </TableCell>
    //         {activeAlertData.map((item: any, index: any) => (
    //           <TableCell
    //             key={index}
    //             style={{
    //               fontSize: "1.3vmin",
    //               textAlign: "center",
    //               width: "100px",
    //             }}
    //           >
    //             {item.notes}
    //           </TableCell>
    //         ))}
    //       </TableRow>
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    <TableContainer
      component={Paper}
      elevation={2}
      style={{ marginTop: "10px" }}
    >
      <Table size="small" style={{ tableLayout: "fixed", width: "100%" }}>
        <TableHead>
          <TableRow style={{ backgroundColor: "rgb(255 253 249)" }}>
            <TableCell
              colSpan={MAX_COLUMNS_PER_ROW + 1}
              className="fw-bold"
              style={{ fontSize: "1.6vmin" }}
            >
              <div className="d-flex justify-content-between">
                <p style={{ width: "100%", margin: 0 }}>ACTIVE ALERTS</p>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                  className="my-0"
                >
                  {legends.map((legend: any, index: any) => (
                    <Grid className="pt-0" item key={index}>
                      <Box
                        display="flex"
                        alignItems="center"
                        // marginRight="10px"
                      >
                        <Box
                          sx={{
                            width: 40,
                            height: 20,
                            backgroundColor: legend.color,
                            border: legend.border || "none",
                            marginRight: 1,
                          }}
                        />
                        <Typography variant="body2">{legend.label}</Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        {/* <TableBody>
          {chunkArray(activeAlertData, MAX_COLUMNS_PER_ROW).map(
            (group, rowIndex, arr) => (
              <React.Fragment key={rowIndex}>
                <TableRow>
                  <TableCell style={{ fontSize: "1.3vmin", width: "100px" }}>
                    Station/Section
                  </TableCell>
                  {group.map((item: any, index: number) => (
                    <TableCell
                      key={index}
                      style={{
                        fontSize: "1.3vmin",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      {item.stationOrSection}
                    </TableCell>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontSize: "1.3vmin", width: "100px" }}>
                    Alert Type
                  </TableCell>
                  {group.map((item: any, index: number) => (
                    <TableCell
                      key={index}
                      style={{
                        fontSize: "1.3vmin",
                        textAlign: "center",
                        backgroundColor: getBackgroundColor(item.alertType),
                        color: "#ffffff",
                        width: "100px",
                      }}
                    >
                      {item.alertType}
                    </TableCell>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontSize: "1.3vmin", width: "100px" }}>
                    Notes
                  </TableCell>
                  {group.map((item: any, index: number) => (
                    <TableCell
                      key={index}
                      style={{
                        fontSize: "1.3vmin",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      {item.notes}
                    </TableCell>
                  ))}
                </TableRow>

                {rowIndex !== arr.length - 1 && (
                  <TableRow>
                    <TableCell
                      colSpan={MAX_COLUMNS_PER_ROW + 1}
                      style={{ height: "10px" }}
                    />
                  </TableRow>
                )}
              </React.Fragment>
            )
          )}
        </TableBody> */}
        <TableBody>
          {activeAlertData.length === 0 ? (
            // Show "No Data Available" row when the data is empty
            <TableRow>
              <TableCell
                colSpan={MAX_COLUMNS_PER_ROW + 1}
                style={{ textAlign: "center", fontSize: "1.3vmin" }}
              >
                No Data Available
              </TableCell>
            </TableRow>
          ) : (
            chunkArray(activeAlertData, MAX_COLUMNS_PER_ROW).map(
              (group, rowIndex, arr) => (
                <React.Fragment key={rowIndex}>
                  <TableRow>
                    <TableCell
                      // className="py-0"
                      style={{
                        fontSize: "1.3vmin",
                        width: "100px",
                        padding: "2px 10px",
                      }}
                    >
                      Station/Section
                    </TableCell>
                    {group.map((item: any, index: number) => (
                      <TableCell
                        key={index}
                        style={{
                          fontSize: "1.3vmin",
                          textAlign: "center",
                          width: "100px",
                          padding: "2px 10px",
                        }}
                        // className="py-0"
                      >
                        {item.stationOrSection}
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "1.3vmin",
                        width: "100px",
                        padding: "1px 10px",
                      }}
                    >
                      Alert Type
                    </TableCell>
                    {group.map((item: any, index: number) => (
                      <TableCell
                        key={index}
                        style={{
                          fontSize: "1.3vmin",
                          textAlign: "center",
                          backgroundColor: getBackgroundColor(item.alertType),
                          color: "#ffffff",
                          width: "100px",
                          padding: "1px 10px",
                        }}
                      >
                        {item.alertType}
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ fontSize: "1.3vmin", width: "100px" }}>
                      Notes
                    </TableCell>
                    {group.map((item: any, index: number) => (
                      <TableCell
                        key={index}
                        style={{
                          fontSize: "1.3vmin",
                          textAlign: "center",
                          width: "100px",
                        }}
                      >
                        {item.notes}
                      </TableCell>
                    ))}
                  </TableRow>

                  {rowIndex !== arr.length - 1 && (
                    <TableRow>
                      <TableCell
                        colSpan={MAX_COLUMNS_PER_ROW + 1}
                        style={{ height: "10px" }}
                      />
                    </TableRow>
                  )}
                </React.Fragment>
              )
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ActiveAlertsData;
