import {
  Container,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";
import _ from "lodash";
import React from "react";
import { toast } from "react-toastify";

interface FinalDetailInterface {
  id: string;
  chassisNo: string;
  model: string;
  priorityNo: string;
  stationAlert: string;
  notes: string;
  line: string;
}

const FinalDetail: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();

  const stationAlertOptions = [
    { label: "Select", value: "" },
    { label: "Supervisor", value: "Supervisor" },
    { label: "Work Active", value: "Work Active" },
    { label: "Work Complete", value: "Work Complete" },
    { label: "Stores", value: "Stores" },
    { label: "Planning", value: "Planning" },
  ];

  const [finalDetailData, setFinalDetailData] = useState<any>({});
  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [editedNotes, setEditedNotes] = useState<string>("");

  const formatFinalDetailData = (data: FinalDetailInterface[]) => {
    let formattedData: any = { Main: [], CRV: [] };

    _.forEach(_.groupBy(data, "line"), (value, key) => {
      if (key.toLowerCase().includes("main")) {
        formattedData.Main = value;
      } else if (key.toLowerCase().includes("crv")) {
        formattedData.CRV = value;
      }
    });

    // Ensure at least one empty row if no data exists
    if (formattedData.Main.length === 0) {
      formattedData.Main.push({
        chassisNo: "",
        model: "",
        priorityNo: "",
        stationAlert: "",
        notes: "",
        line: "Main",
      });
    }
    if (formattedData.CRV.length === 0) {
      formattedData.CRV.push({
        chassisNo: "",
        model: "",
        priorityNo: "",
        stationAlert: "",
        notes: "",
        line: "CRV",
      });
    }

    return formattedData;
  };

  const fetchFinalDetailData = async () => {
    try {
      const res = await axiosPrivate.get("get_all_final_detail_list");
      const groupedData = formatFinalDetailData(res.data);
      setFinalDetailData(groupedData);
    } catch (error) {
      console.error("Error fetching alert names:", error);
    }
  };

  useEffect(() => {
    fetchFinalDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackgroundColor = (stationAlert: any) => {
    // if (stationAlert == null || stationAlert == "") return "inherit";

    const colorMap: Record<string, string> = {
      Supervisor: "#FF0000",
      "Work Active": "#FEB124",
      "Work Complete": "rgb(13 222 13)",
      Stores: "#0000FF",
      Planning: "#000000",
    };
    return colorMap[stationAlert] || "inherit";
  };

  const handleAlertOptionsChange = async (row: any, value: any) => {
    let requestBody = {
      stationAlert: value,
    };
    try {
      await axiosPrivate.put(
        `update_station_alert_to_final_detail/${row.id}`,
        requestBody
      );
      await fetchFinalDetailData();
      toast.success("Saved Successfully!");
    } catch (e) {
      toast.error("Failed!");
    }
  };

  const startEditing = (row: any) => {
    setEditingRowId(row.chassisNo); // Set the row being edited
    setEditedNotes(row.notes || ""); // Load existing notes
  };

  const saveNotes = async (row: any) => {
    setFinalDetailData((prevData: any) => {
      const updatedData = { ...prevData };
      Object.keys(updatedData).forEach((line) => {
        updatedData[line] = updatedData[line].map((item: any) =>
          item.chassisNo === row.chassisNo
            ? { ...item, notes: editedNotes }
            : item
        );
      });
      return updatedData;
    });
     let requestBody = {
       notes: editedNotes,
     };
    try {
      await axiosPrivate.put(
        `update_notes_to_final_detail/${row.id}`,
        requestBody
      );
      await fetchFinalDetailData();
      toast.success("Saved Successfully!");
    } catch (e) {
      toast.error("Failed!");
    }

    setEditingRowId(null); // Exit edit mode
  };

  return (
    <Container>
      <TableContainer
        component={Paper}
        style={{ marginTop: "8px", minWidth: "1150px" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
              <TableCell style={{ width: "100px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#fff",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Station Name
                </Typography>
              </TableCell>
              <TableCell style={{ width: "80px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#fff",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Line View
                </Typography>
              </TableCell>
              <TableCell style={{ width: "85px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#fff",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Priority No
                </Typography>
              </TableCell>
              <TableCell style={{ width: "100px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#fff",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Chassis No
                </Typography>
              </TableCell>

              <TableCell style={{ width: "120px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#fff",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Station Alert
                </Typography>
              </TableCell>

              <TableCell style={{ width: "200px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#fff",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Model
                </Typography>
              </TableCell>
              <TableCell style={{ width: "200px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#fff",
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Notes
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                rowSpan={
                  (finalDetailData?.Main
                    ? Object.keys(finalDetailData.Main).length
                    : 0) +
                  (finalDetailData?.CRV
                    ? Object.keys(finalDetailData.CRV).length
                    : 0) +
                  1
                }
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: 500,
                    fontFamily: "League Spartan, sans-serif",
                  }}
                >
                  Final Detail
                </Typography>
              </TableCell>
            </TableRow>

            {/* Loop over each line (grouped data) */}
            {Object.keys(finalDetailData).map((line: string, i: number) => (
              <React.Fragment key={i}>
                {finalDetailData[line]?.length > 0 ? (
                  finalDetailData[line].map((row: any, rowIndex: any) => (
                    <TableRow key={rowIndex}>
                      {/* Line Name should only appear in the first row */}
                      {rowIndex === 0 && (
                        <TableCell
                          rowSpan={finalDetailData[line].length}
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                            textAlign: "center",
                            borderBottomColor: "red",
                          }}
                        >
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                            }}
                          >
                            {line}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          {row.priorityNo}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          {row.chassisNo}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          <Select
                            name="stationAlert"
                            value={row?.stationAlert ?? ""}
                            onChange={(event) =>
                              handleAlertOptionsChange(row, event.target.value)
                            }
                            className="station_alert_dropdown"
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            sx={{
                              width: "100%",
                              fontFamily: "League Spartan, sans-serif",
                              backgroundColor: getBackgroundColor(
                                row?.stationAlert
                              ),
                              color:
                                row?.stationAlert === "Supervisor" ||
                                row?.stationAlert === "Work Active" ||
                                row?.stationAlert === "Work Complete" ||
                                row?.stationAlert === "Stores" ||
                                row?.stationAlert === "Planning"
                                  ? "white"
                                  : "black",
                              ".MuiSvgIcon-root": {
                                color:
                                  row?.stationAlert === "Planning"
                                    ? "white !important"
                                    : "inherit",
                              },
                            }}
                          >
                            {stationAlertOptions.map((item) => (
                              <MenuItem
                                value={item.value}
                                style={{
                                  fontFamily: "League Spartan, sans-serif",
                                }}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          {row.model}
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                        <Typography
                          variant="body1"
                          style={{
                            fontFamily: "League Spartan, sans-serif",
                          }}
                        >
                          {row.notes}
                        </Typography>
                      </TableCell> */}
                      <TableCell>
                        {editingRowId === row.chassisNo ? (
                          <TextField
                            autoFocus
                            // variant="outlined"
                            size="small"
                            value={editedNotes}
                            onChange={(event) =>
                              setEditedNotes(event.target.value)
                            }
                            // onBlur={() => saveNotes(row)}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                saveNotes(row);
                              }
                            }}
                            sx={{
                              fontFamily: "League Spartan, sans-serif",
                              width: "100%",
                              height: "100%",
                              "& .MuiInputBase-root": {
                                padding: "0px", // Ensures input height consistency
                              },
                              "& .MuiOutlinedInput-root": {
                                height: "32px", // Adjust this value to match your Typography height
                                display: "flex",
                                alignItems: "center",
                              },
                            }}
                          />
                        ) : (
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "League Spartan, sans-serif",
                              cursor: "pointer",
                              minHeight: "24px", // Ensures consistent height even if notes are empty
                            }}
                            onClick={() => startEditing(row)}
                          >
                            {row.notes || ""}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  /* If no data exists for this line, render a single row */
                  <TableRow>
                    <TableCell
                      rowSpan={1}
                      style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                    >
                      {line} Line
                    </TableCell>
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      No Data
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default FinalDetail;
