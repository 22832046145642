import "./App.css";
import { ToastContainer } from "react-toastify";
import DailyReview from "./app/features/dailyReview/dailyReview";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./app/common/Layout";
import Login from "./app/authentications/Login";
import RequireAuth from "./app/authentications/RequireAuth";
import ProductionScheduleSetting from "./app/features/scheduleFeed/ProductionScheduleSettings";
import ScheduleFeed from "./app/features/scheduleFeed/scheduleFeed";
import { useSelector } from "react-redux";
import { IUserInfo } from "./app/store/actions";
import PickList from "./app/features/pickList/PickList";
import ProductionReviewSetting from "./app/features/dailyReview/components/ProductionReviewSetting/ProductionReviewSetting";
import ProductionReview from "./app/features/ProductionReviewForBigScreen/ProductionReview";
import QualityAssurance from "./app/features/QualityAssurance/QualityAssurance";
import { useEffect } from "react";
import Dashboard from "./app/features/Admin/Dashboard";
import AdminLayout from "./app/features/Admin/AdminLayout";
import Users from "./app/features/Admin/Users";
import AlertSMS from "./app/features/Admin/AlertSms/AlertSms";
import FinalDetail from "./app/features/dailyReview/components/FinalDetail/FinalDetail";
import ComplianceQueue from "./app/features/dailyReview/components/ComplinaceQueue/ComplinaceQueue";
import FinalInspectionQueue from "./app/features/dailyReview/components/FinalInspectionQueue/FInalInspectionQueue";
// import { AlertSMS } from "./app/features/Admin/AlertSms/AlertSms";

function App() {
  const navigate = useNavigate();
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );

  const hasRole = (role: any) => authInfo?.roles?.includes(role);
  const isPrepTypeRole =
    hasRole("chassis_prep") ||
    hasRole("wall_assembly_prep") ||
    hasRole("roof_assembly_prep") ||
    hasRole("doors_and_drawers_prep") ||
    hasRole("furniture_prep") ||
    hasRole("cnc_prep") ||
    hasRole("cnc_doors_drawers_prep");

  useEffect(() => {
    if (!authInfo || !authInfo.roles || authInfo.roles.length === 0) {
      navigate("/login");
    }
  }, [authInfo, navigate]);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route element={<RequireAuth />}>
            {hasRole("user.admin") && (
              <>
                <Route path="/schedule" element={<ScheduleFeed />} />
                <Route path="/admin" element={<AdminLayout />}>
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="alertsms" element={<AlertSMS />} />
                  {/* <Route path="reports" element={<Reports />} /> */}
                  {/* <Route path="settings" element={<Settings />} /> */}
                </Route>
              </>
            )}
            {(hasRole("production-manager") ||
              hasRole("user.admin") ||
              hasRole("production-scheduler") ||
              isPrepTypeRole) && (
              <Route path="/daily-review" element={<DailyReview />} />
            )}
            {/* {hasRole("user.admin") && ( <Route path="/form" element={<ResponsiveForm />} /> )} */}
            {(hasRole("user.admin") ||
              hasRole("production-manager") ||
              hasRole("production-scheduler")) && (
              <Route
                path="/production-review-setting"
                element={<ProductionReviewSetting />}
              />
            )}
            {(hasRole("user.admin") || hasRole("production-manager")) && (
              <Route
                path="/production-schedule-setting"
                element={
                  <ProductionScheduleSetting></ProductionScheduleSetting>
                }
              />
            )}
            {(hasRole("user.admin") || hasRole("store")) && (
              <Route path="/pick-list" element={<PickList />} />
            )}
            {hasRole("chassis-elec-qa") && (
              <Route path="/quality-assurance" element={<QualityAssurance />} />
            )}
            {hasRole("quality-assurance") && (
              <>
                <Route path="/final-detail" element={<FinalDetail />} />
                <Route
                  path="/final-inspection"
                  element={<FinalInspectionQueue />}
                />
              </>
            )}
            {hasRole("compliance") && (
              <>
                <Route path="/compliance" element={<ComplianceQueue />} />
              </>
            )}
            {hasRole("operation-manager") && (
              <Route
                path="/crusader-production-system"
                element={<ProductionReview />}
              />
            )}
          </Route>
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
