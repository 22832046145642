import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

const PrepDataTable = (props: any) => {
    const {rowName, prepData, line} = props;

    const getBackgroundColor = (stationAlert: any) => {
      const colorMap: Record<string, string> = {
        Supervisor: "#FF0000",
        "Work Active": "#FEB124",
        "Work Complete": "rgb(13 222 13)",
        Stores: "#0000FF",
        Planning: "#000000",
      };
      return colorMap[stationAlert] || "inherit";
    };

    return (
      <TableContainer
        // component={Paper}
        // elevation={3}
        style={{ border: "1px solid rgb(213 213 213)" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow
              className="bs-prep-table--row"
              style={{ width: "100%", backgroundColor: "rgb(229, 216, 245)" }}
            >
              {line === "main" && (
                // <TableCell
                //   colSpan={3}
                //   className="text-center fw-bold py-0"
                //   style={{
                //     fontSize: "1.6vmin",
                //     width: "100%",
                //     textTransform: "uppercase",
                //     borderBottomColor: "rgba(175, 175, 175, 0.87)",
                //   }}
                // >
                //   {rowName}
                // </TableCell>
                <TableCell
                  colSpan={3}
                  className="text-center fw-bold py-0"
                  style={{
                    fontSize: "1.6vmin",
                    width: "100%",
                    textTransform: "uppercase",
                    borderBottomColor: "rgba(175, 175, 175, 0.87)",
                    paddingTop: "0px", // Ensures no padding at the top
                    paddingBottom: "0px", // Ensures no padding at the bottom
                    marginTop: "0px", // Prevents any margin from pushing the text
                    marginBottom: "0px", // Prevents any margin below the text
                    // lineHeight: "1", // Helps reduce vertical spacing
                  }}
                >
                  {rowName}
                </TableCell>
              )}
            </TableRow>
            {/* <TableRow
              className="bs-prep-table--row"
              style={{ width: "100%", backgroundColor: "rgb(229, 216, 245)" }}
            >
              <TableCell
                colSpan={3}
                className="text-center py-0"
                style={{ fontSize: "1.5vmin", width: "100%" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="text-uppercase">{line} Line</span>
                  <span>
                    Complete Jobs:{" "}
                    {
                      prepData[rowName][0][line].filter(
                        (row: any) => row.stationAlert === "Work Complete"
                      ).length
                    }{" "}
                    of {prepData[rowName][0][line].length}
                  </span>
                </div>
              </TableCell>
            </TableRow> */}
            <TableRow
              className="bs-prep-table--row"
              style={{
                width: "100%",
                backgroundColor: "rgb(229, 216, 245)",
                margin: "0",
                padding: "0",
              }}
            >
              <TableCell
                colSpan={3}
                className="text-center py-0"
                style={{
                  fontSize: "1.5vmin",
                  width: "100%",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  lineHeight: "1.3", // Ensures no extra space above or below text
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px",
                    margin: "0px",
                    // lineHeight: "1", // Ensures the div also has minimal vertical spacing
                  }}
                >
                  <span
                    className="text-uppercase"
                    style={{ margin: "0px", padding: "0px" }}
                  >
                    {line} Line
                  </span>
                  <span style={{ margin: "0px", padding: "0px" }}>
                    Complete Jobs:{" "}
                    {
                      prepData[rowName][0][line].filter(
                        (row: any) => row.stationAlert === "Work Complete"
                      ).length
                    }{" "}
                    of {prepData[rowName][0][line].length}
                  </span>
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="py-0" style={{ fontSize: "1.4vmin" }}>
                Sequence
              </TableCell>
              <TableCell className="py-0" style={{ fontSize: "1.4vmin" }}>
                Chassis #
              </TableCell>
              <TableCell className="py-0" style={{ fontSize: "1.4vmin" }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prepData[rowName][0][line].map((row: any, i: number) => (
              <TableRow key={i}>
                <TableCell className="py-0" style={{ fontSize: "1.6vmin" }}>
                  {row.priorityNo}
                </TableCell>
                <TableCell className="py-0" style={{ fontSize: "1.6vmin" }}>
                  {row.chassisNo}
                </TableCell>
                <TableCell
                  className="py-0"
                  style={{
                    fontSize: "1.6vmin",
                    // backgroundColor: getBackgroundColor(row.stationAlert),
                  }}
                >
                  {/* {row.stationAlert} */}
                  <Box
                    sx={{
                      width: 40,
                      height: 20,
                      backgroundColor: getBackgroundColor(row.stationAlert),
                      border: row.stationAlert ? "1px solid black" : "",
                      marginRight: 1,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
};

export default PrepDataTable;