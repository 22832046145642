import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { IUserInfo } from "../store/actions";

const RequireAuth = ({ allowedRoles }: any) => {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  const location = useLocation();
  // console.log("Auth Info:", authInfo);
  // return authInfo?.accessToken ? (
  //   <>
  //   <Outlet />
  //   </>
  // ) : (
  //   <>
  //   <Navigate to="/login" state={{ from: location }} replace />
  //   </>
  // );
  if (!authInfo || !authInfo.accessToken) {
    console.log("User not logged in, redirecting to login...");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export default RequireAuth;
