import React, { useState } from "react";
import _ from "lodash";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";

const { REACT_APP_API_WEBSOCKET_URL } = process.env;

export default function ProductionLineTimer({
  selectedLineValue,
  refreshProdLine,
  refreshChassisPreparation,
}: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTime, setCurrentTime] = useState(new Date());
  const [count, setCount] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  let lineRelatedValue: any = { line: null };
  const refresh = async (lineName: string) => {
    await axiosPrivate.get(`refresh_main_crv_line_count_down/${lineName}`);
  };
  React.useEffect(() => {
    try {
      lineRelatedValue.line = _.first(selectedLineValue.split("-"));
      localStorage.setItem("line-val", lineRelatedValue.line);
      refresh(lineRelatedValue.line);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLineValue]);

  React.useEffect(() => {
    const lineVal = _.first(selectedLineValue.split("-")) as string;
    localStorage.setItem("line-val", lineVal);
    const ws = new WebSocket(
      `${REACT_APP_API_WEBSOCKET_URL}/ws/timer/${lineRelatedValue.line}`
    );
    ws.onmessage = (event) => {
      let data = JSON.parse(event.data);
      let line = localStorage.getItem("line-val") || lineVal;
      if (data.line === line) {
        if (data.value === 0) {
          refreshProdLine();
          refreshChassisPreparation();
        }
        setCount(data.value);
      }
    };

    setTimeout(() => {
      refresh(lineVal);
    }, 500);

    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="d-flex align-items-center"
      style={{ justifyContent: "space-between" }} // Ensures proper spacing
    >
      <h6
        style={{
          margin: "0 10px 0 0", // Adds spacing between label and timer
          fontSize: "3vmin",
          fontWeight: "bold",
          whiteSpace: "nowrap", // Prevents text from breaking into multiple lines
        }}
      >
        Cycle Time:
      </h6>
      <div
        style={{
          width: `${Math.max(60, String(count).length * 30)}px`,
          height: "55px",
          border: "2px solid black",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Centers text vertically
          color: "#763497",
          padding: "5px",
        }}
      >
        <h6
          className="mb-0 fw-bold"
          style={{ fontSize: "5vmin", fontFamily: "cursive" }}
        >
          {count !== null ? count : 0}
        </h6>
      </div>
    </div>
  );
}
