import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IUserInfo } from "../../../../store/actions";
import _ from "lodash";
import useAxiosPrivate from "../../../../common/useAxiosPrivate";
import AlertModal from "../assembly/AlertModal";
import ScheduledChassisForCNC from "./SchdeuledChassisForCNC";
// import httpClient from "../../../../https/httpClient";

const prepTypes = {
  chassis_prep: 0,
  wall_assembly_prep: 1,
  roof_assembly_prep: 2,
  doors_and_drawers_prep: 3,
  furniture_prep: 4,
  cnc_prep: 5,
  cnc_doors_drawers_prep: 6,
};
export default function Preparation() {
  const authInfo: IUserInfo = useSelector(
    (state: any) => state.userAuthInfoState
  );
  const [allPrepData, setALLPrepData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loader, setLoader] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const [stationName, setStationName] = useState<string>("");
  const [lineName, setLineName] = useState<string>("");
  const [dataRowIndex, setDataRowIndex] = useState<number>();
  const [isInputField, setIsInputField] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [prepEditedNotes, setPrepEditedNotes] = useState<string>("");
  const [selectedStation, setSelectedStation] = useState<any>(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [newAlertNotes, setNewAlertNotes] = useState("");

  const stationAlertOptions = [
    { label: "Select", value: "" },
    { label: "Supervisor", value: "Supervisor" },
    { label: "Work Active", value: "Work Active" },
    { label: "Work Complete", value: "Work Complete" },
    { label: "Stores", value: "Stores" },
    { label: "Planning", value: "Planning" },
  ];
  const cncMachineOptions = [
    { label: "Select", value: "" },
    { label: "Woodtron 3", value: "Woodtron 3" },
    { label: "Woodtron 4", value: "Woodtron 4" },
    { label: "Woodtron 5", value: "Woodtron 5" },
  ];

  const CNCDoorsAndDrawersOptions = [
    { label: "Select", value: "" },
    { label: "Woodtron 1", value: "Woodtron 1" },
    { label: "Woodtron 2", value: "Woodtron 2" },
  ];

  const getPreparationData = async () => {
    const result = await axiosPrivate.get(
      `get_all_chassis_preparation_queue_report`
    );

    let formatedData: any = {};
    _.forIn(
      _.groupBy(result.data, (d) => {
        return d.stationName;
      }),
      function (value, key) {
        let obj: any = {};
        _.each(value, (v) => {
          if (
            !v.chassisPreparationQueues ||
            v.chassisPreparationQueues.length === 0
          ) {
            obj[v.line] = [
              {
                id: "",
                chassisNo: "",
                checked: false,
                model: "",
                priorityNo: "",
                stationAlert: "",
                notes: "",
                cncMachine: "",
                uploadedToCncMachine: false,
              },
            ];
          } else {
            obj[v.line] = _.map(v.chassisPreparationQueues, (q) => {
              return {
                id: v.id,
                chassisNo: q.chassisNo,
                checked: q.checked,
                model: q.model,
                priorityNo: q.priorityNo,
                stationAlert: q.stationAlert,
                notes: q.notes,
                cncMachine: q.cncMachine,
                uploadedToCncMachine: q.uploadedToCncMachine,
              };
            });
          }
        });
        formatedData[key] = [obj];
      }
    );
    setALLPrepData([formatedData]);
  };

  useEffect(() => {
    getPreparationData();
    let id = setInterval(() => {
      getPreparationData();
    }, 15 * 1000);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleAlertOptionsChangePrev = async (row: any, value: any) => {
  //   let requestBody = {
  //     chassisNo: row.chassisNo,
  //     stationAlert: value,
  //   };
  //   try {
  //     await axiosPrivate.put(
  //       `update_station_alert_to_preparation_queue/${row.id}`,
  //       requestBody
  //     );
  //     await getPreparationData();
  //     toast.success("Saved Successfully!");
  //   } catch (e) {
  //     toast.error("Failed!");
  //   }
  // };

  const handleAlertOptionsChange = async (row: any, value: any) => {
    const alertValues = ["Planning", "Supervisor", "Stores"];
    if (alertValues.includes(value)) {
      setSelectedStation({ ...row, newAlertValue: value }); // Ensure this is correctly set
      setOpenAlertModal(true);
      return;
    }
    await submitStationAlert(row, value, row.notes);
  };

  const submitStationAlert = async (row: any, value: any, notesValue: any) => {
    let requestBody = {
      chassisNo: row.chassisNo,
      stationAlert: value,
      notes: notesValue,
    };
    try {
      await axiosPrivate.put(
        `update_station_alert_to_preparation_queue/${row.id}`,
        requestBody
      );
      await getPreparationData();
      setNewAlertNotes("");
      toast.success("Saved Successfully!");
      setOpenAlertModal(false);
    } catch (e) {
      toast.error("Failed!");
      setOpenAlertModal(false);
      setNewAlertNotes("");
    }
  };

  const handleCncMachineOptionsChange = async (row: any, value: any) => {
    try {
      let text =
        "If the CNC PC and Machines are on press ok. Otherwise, press cancel.";
      if (window.confirm(text)) {
        let requestBody = {
          chassisNo: row.chassisNo,
          cncMachine: value,
        };

        await axiosPrivate.put(
          `update_cnc_machine_to_preparation_queue/${row.id}`,
          requestBody
        );
        await getPreparationData();
        toast.success("Successfully saved.");
      } else {
        await getPreparationData();
      }
    } catch (e) {
      toast.error("Failed!");
    }
  };

  const isAdmin =
    authInfo.roles &&
    (authInfo.roles.includes("user.admin") ||
      authInfo.roles?.includes("production-manager") ||
      authInfo.roles?.includes("production-scheduler"));

  const prepType = isAdmin
    ? null
    : (Object.keys(prepTypes).find((key) =>
        authInfo.roles?.includes(key)
      ) as keyof typeof prepTypes);

  const getBackgroundColor = (isAdmin: any, stationAlert: any) => {
    if (!isAdmin) return "inherit";

    const colorMap: Record<string, string> = {
      Supervisor: "#FF0000",
      "Work Active": "#FEB124",
      "Work Complete": "rgb(13 222 13)",
      Stores: "#0000FF",
      Planning: "#000000",
    };

    return colorMap[stationAlert] || "inherit";
  };

  const getTextColor = (isAdmin: any, stationAlert: any) => {
    if (!isAdmin) return "inherit";

    const colorMap: Record<string, string> = {
      Supervisor: "#ffffff",
      "Work Active": "#ffffff",
      "Work Complete": "#ffffff",
      Stores: "#ffffff",
      Planning: "#ffffff",
    };

    return colorMap[stationAlert] || "inherit";
  };

  const PreperationTableHead = ({
    isStationCnc,
  }: {
    isStationCnc: boolean;
  }) => (
    <TableHead>
      <TableRow style={{ backgroundColor: "rgb(59, 17, 80)" }}>
        <TableCell style={{ width: "100px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Station Name
          </Typography>
        </TableCell>
        <TableCell style={{ width: "80px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Line View
          </Typography>
        </TableCell>
        <TableCell style={{ width: "85px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Priority No
          </Typography>
        </TableCell>
        {isStationCnc ? (
          <TableCell style={{ width: "85px" }}>
            <Typography
              variant="subtitle1"
              style={{
                color: "#fff",
                fontFamily: "League Spartan, sans-serif",
              }}
            >
              CNC
            </Typography>
          </TableCell>
        ) : null}
        <TableCell style={{ width: "100px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Chassis No
          </Typography>
        </TableCell>
        {(prepType || isAdmin) && (
          <TableCell style={{ width: "120px" }}>
            <Typography
              variant="subtitle1"
              style={{
                color: "#fff",
                fontFamily: "League Spartan, sans-serif",
              }}
            >
              Station Alert
            </Typography>
          </TableCell>
        )}
        <TableCell style={{ width: "200px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Model
          </Typography>
        </TableCell>
        <TableCell style={{ width: "200px" }}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#fff",
              fontFamily: "League Spartan, sans-serif",
            }}
          >
            Notes
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  // enable enc machine column for only cnc_prep station
  let isStationCnc: boolean = false;
  if (
    prepType?.toLowerCase() === "cnc_prep" ||
    prepType?.toLowerCase() === "cnc_doors_drawers_prep"
  ) {
    isStationCnc = true;
  }

  const handleIsNoteInputFieldActive: any = (
    stationName: string,
    lineName: string,
    dataRowIndex: number
  ) => {
    setIsInputField(true);
    setStationName(stationName);
    setLineName(lineName);
    setDataRowIndex(dataRowIndex);
  };

  const handlePrepEditedNotes = (
    event: any,
    station: string,
    line: string,
    rowIndex: number
  ) => {
    setPrepEditedNotes(event.target.value);
    allPrepData[0][station][0][line][rowIndex].notes = event.target.value;
  };
  const handlePostPrepNotes = async (event: any, row: any) => {
    setIsInputField(false);
    let requestBody = {
      chassisNo: row.chassisNo,
      notes: event.target.value,
    };
    console.log(requestBody);
    try {
      await toast.promise(
        axiosPrivate.put(
          `update_notes_to_preparation_queue/${row.id}`,
          requestBody
        ),
        {
          pending: "Request pending",
        }
      );

      toast.success("Request Success");
      getPreparationData();
    } catch (error) {
      console.log("error", error);
      toast.error("Invalid Input");
    }
  };

  return (
    <div className="p-0 ms-0 pb-4" style={{ textAlign: "center" }}>
      {isAdmin || prepType ? (
        <>
          <TableContainer
            component={Paper}
            style={{ marginTop: "8px", minWidth: "1150px" }}
          >
            <Table size="small">
              <PreperationTableHead isStationCnc={isStationCnc} />
              <TableBody>
                {loader ? (
                  <Typography className="loading">Loading...</Typography>
                ) : (
                  <>
                    {allPrepData &&
                    allPrepData.length > 0 &&
                    Object.keys(allPrepData[0]).length > 0 ? (
                      Object.keys(allPrepData[0]).map(
                        (station: string, i: number) => (
                          <React.Fragment key={i}>
                            {(isAdmin ||
                              (prepType && i === prepTypes[prepType])) &&
                              Object.keys(allPrepData[0][station][0]).map(
                                (line: any, idx: number) =>
                                  allPrepData[0][station][0][line].map(
                                    (row: any, index: number) => (
                                      <TableRow
                                        key={index}
                                        style={{
                                          borderBottom:
                                            (line === "main" &&
                                              index ===
                                                allPrepData[0][station][0][
                                                  "main"
                                                ].length -
                                                  1) ||
                                            (idx === 1 &&
                                              index ===
                                                allPrepData[0][station][0][
                                                  "crv"
                                                ].length -
                                                  1 &&
                                              isAdmin)
                                              ? "4px solid #d0d0d0"
                                              : "inherit",
                                        }}
                                      >
                                        {index === 0 && idx === 0 && (
                                          <TableCell
                                            rowSpan={
                                              allPrepData[0][station][0]["main"]
                                                ?.length +
                                              allPrepData[0][station][0]["crv"]
                                                ?.length
                                            }
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              {station}
                                            </Typography>
                                          </TableCell>
                                        )}
                                        {index === 0 && (
                                          <TableCell
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                            rowSpan={
                                              allPrepData[0][station][0][line]
                                                ?.length || 1
                                            }
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                color:
                                                  line === "crv"
                                                    ? "blue"
                                                    : "black",
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              {line}
                                            </Typography>
                                          </TableCell>
                                        )}
                                        <TableCell
                                          style={{
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(
                                                isAdmin,
                                                row?.stationAlert
                                              ),
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {/* {prepType && (
                                              <Checkbox
                                                checked={row?.checked}
                                                onChange={async (event) => {
                                                  handleCheckboxChange(
                                                    event,
                                                    row
                                                  );
                                                }}
                                              />
                                            )} */}
                                            {row?.priorityNo}
                                          </Typography>
                                        </TableCell>

                                        {/* CNC Machine block start */}
                                        {isStationCnc ? (
                                          <TableCell
                                            style={{
                                              backgroundColor:
                                                getBackgroundColor(
                                                  isAdmin,
                                                  row?.stationAlert
                                                ),
                                            }}
                                          >
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                color: getTextColor(
                                                  isAdmin,
                                                  row?.stationAlert
                                                ),
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              <Select
                                                disabled={
                                                  (row?.cncMachine ?? "") !== ""
                                                    ? true
                                                    : false
                                                }
                                                name="cncMachineDropDown"
                                                value={row?.cncMachine ?? ""}
                                                onChange={(event) =>
                                                  handleCncMachineOptionsChange(
                                                    row,
                                                    event.target.value
                                                  )
                                                }
                                                className="station_alert_dropdown"
                                                displayEmpty
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                                style={{
                                                  width: "100%",
                                                  fontFamily:
                                                    "League Spartan, sans-serif",
                                                  backgroundColor:
                                                    "transparent",
                                                  color: "black",
                                                }}
                                              >
                                                {(authInfo.roles?.includes("cnc_prep")
                                                  ? cncMachineOptions
                                                  : CNCDoorsAndDrawersOptions
                                                ).map((item) => (
                                                  <MenuItem
                                                    key={item.value} // Always add a unique key in React lists
                                                    value={item.value}
                                                    style={{
                                                      fontFamily:
                                                        "League Spartan, sans-serif",
                                                    }}
                                                  >
                                                    {item.label}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </Typography>
                                          </TableCell>
                                        ) : null}
                                        {/* CNC Machine block ends */}

                                        <TableCell
                                          style={{
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(
                                                isAdmin,
                                                row?.stationAlert
                                              ),
                                              fontWeight: 500,
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                            }}
                                          >
                                            {row?.chassisNo}
                                          </Typography>
                                        </TableCell>
                                        {prepType ? (
                                          <TableCell
                                            style={{
                                              backgroundColor:
                                                getBackgroundColor(
                                                  isAdmin,
                                                  row?.stationAlert
                                                ),
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              style={{
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              <Select
                                                name="stationAlert"
                                                value={row?.stationAlert ?? ""}
                                                onChange={(event) =>
                                                  handleAlertOptionsChange(
                                                    row,
                                                    event.target.value
                                                  )
                                                }
                                                className="station_alert_dropdown"
                                                displayEmpty
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                                sx={{
                                                  width: "100%",
                                                  fontFamily:
                                                    "League Spartan, sans-serif",
                                                  backgroundColor:
                                                    row?.stationAlert ===
                                                    "Supervisor"
                                                      ? "#FF0000"
                                                      : row?.stationAlert ===
                                                        "Work Active"
                                                      ? "#FEB124"
                                                      : row?.stationAlert ===
                                                        "Work Complete"
                                                      ? "rgb(13 222 13)"
                                                      : row?.stationAlert ===
                                                        "Stores"
                                                      ? "#0000FF"
                                                      : row?.stationAlert ===
                                                        "Planning"
                                                      ? "#000000"
                                                      : "transparent",
                                                  color:
                                                    row?.stationAlert ===
                                                      "Supervisor" ||
                                                    row?.stationAlert ===
                                                      "Work Active" ||
                                                    row?.stationAlert ===
                                                      "Work Complete" ||
                                                    row?.stationAlert ===
                                                      "Stores" ||
                                                    row?.stationAlert ===
                                                      "Planning"
                                                      ? "white"
                                                      : "black",
                                                  ".MuiSvgIcon-root": {
                                                    color:
                                                      row?.stationAlert ===
                                                      "Planning"
                                                        ? "white !important"
                                                        : "inherit",
                                                  },
                                                }}
                                              >
                                                {stationAlertOptions.map(
                                                  (item) => (
                                                    <MenuItem
                                                      value={item.value}
                                                      style={{
                                                        fontFamily:
                                                          "League Spartan, sans-serif",
                                                      }}
                                                    >
                                                      {item.label}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </Typography>
                                          </TableCell>
                                        ) : isAdmin ? (
                                          <TableCell
                                            style={{
                                              backgroundColor:
                                                getBackgroundColor(
                                                  isAdmin,
                                                  row?.stationAlert
                                                ),
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: getTextColor(
                                                  isAdmin,
                                                  row?.stationAlert
                                                ),
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {row?.stationAlert ?? ""}
                                            </Typography>
                                          </TableCell>
                                        ) : (
                                          <></>
                                        )}
                                        <TableCell
                                          style={{
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            style={{
                                              color: getTextColor(
                                                isAdmin,
                                                row?.stationAlert
                                              ),
                                              fontFamily:
                                                "League Spartan, sans-serif",
                                              fontWeight: 500,
                                            }}
                                          >
                                            {row?.model}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            backgroundColor: getBackgroundColor(
                                              isAdmin,
                                              row?.stationAlert
                                            ),
                                          }}
                                          onClick={() =>
                                            handleIsNoteInputFieldActive(
                                              station,
                                              line,
                                              index
                                            )
                                          }
                                        >
                                          {prepType &&
                                          row?.chassisNo &&
                                          stationName === station &&
                                          lineName === line &&
                                          dataRowIndex === index &&
                                          isInputField ? (
                                            <input
                                              type="text"
                                              value={row?.notes ?? ""}
                                              onChange={(event) =>
                                                handlePrepEditedNotes(
                                                  event,
                                                  station,
                                                  line,
                                                  index
                                                )
                                              }
                                              onKeyDown={(e) =>
                                                e.key === "Enter" &&
                                                handlePostPrepNotes(e, row)
                                              }
                                            />
                                          ) : (
                                            <Typography
                                              variant="subtitle1"
                                              style={{
                                                color: getTextColor(
                                                  isAdmin,
                                                  row?.stationAlert
                                                ),
                                                fontWeight: 500,
                                                fontFamily:
                                                  "League Spartan, sans-serif",
                                              }}
                                            >
                                              {row?.notes}
                                            </Typography>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )
                              )}
                          </React.Fragment>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={7}
                          align="center"
                          style={{
                            padding: "20px",
                            fontSize: "18px",
                            fontWeight: 500,
                          }}
                        >
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {authInfo.roles.includes("cnc_prep") && <ScheduledChassisForCNC />}

          {openAlertModal && (
            <AlertModal
              openModal={openAlertModal}
              setOpenModal={setOpenAlertModal}
              notes={newAlertNotes}
              setNotes={setNewAlertNotes}
              submitStationAlert={submitStationAlert}
              selectedStation={selectedStation}
            />
          )}
        </>
      ) : (
        <Typography
          variant="body1"
          style={{ marginTop: "8px", fontFamily: "League Spartan, sans-serif" }}
        >
          Not a valid role!!!
        </Typography>
      )}
    </div>
  );
}
