import React, { useState } from "react";
import useAxiosPrivate from "../../common/useAxiosPrivate";
const { REACT_APP_API_WEBSOCKET_URL } = process.env;

const CycleTimer = ({ line }: { line: string }) => {
  const [count, setCount] = useState<number | null>(null);
  const axiosPrivate = useAxiosPrivate();
  const refresh = async()=>{
      await axiosPrivate.get(`refresh_main_crv_line_count_down/${line}`)
    }
  
  React.useEffect(() => {
    refresh();
  })
  React.useEffect(() => {
    // const ws = new WebSocket(`ws://${REACT_APP_API_BASE_URL_TWO}/ws/countdown/${line}`);
    const ws = new WebSocket(`${REACT_APP_API_WEBSOCKET_URL}/ws/timer/${line}`);
    ws.onmessage = (event) => {
      // console.log(event.data)
      let data = JSON.parse(event.data)
          // console.log(line)
          if(data.line === line){
            setCount(data.value)
          }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex align-items-center">
      <span style={{ paddingRight: "10px" }}>Cycle Time:</span>
      <div
        style={{
          textAlign: "center",
          // border: "1px solid black",
          width:
            count !== null && count.toString().length > 1 ? "70px" : "35px",
          height: "30px",
          // borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bolder",
          color: "rgb(76, 24, 102)",
        }}
      >
        {count !== null ? (
          <div
            style={{ fontSize: "4vmin", paddingTop: "6px", fontWeight: "700" }}
          >
            {count}
          </div>
        ) : (
          <div
            style={{ fontSize: "4vmin", paddingTop: "6px", fontWeight: "700" }}
          >
            0
          </div>
        )}
      </div>
    </div>
  );
};

export default CycleTimer;
