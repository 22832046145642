import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

const ProdLineModal = (props: any) => {
  const { open, handleClose, station, handleSave } = props;
  const [editedData, setEditedData] = useState({
    priorityNo: station.priorityNo || "",
    chassisNo: station.chassisNo || "",
    // notes: station.notes || "",
  });

  const handleChange = (e: any) => {
    setEditedData({ ...editedData, [e.target.name]: e.target.value });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Station</DialogTitle>
      <DialogContent>
        <TextField
          label="Priority No"
          name="priorityNo"
          fullWidth
          margin="dense"
          value={editedData.priorityNo}
          onChange={handleChange}
        />
        <TextField
          label="Chassis No"
          name="chassisNo"
          fullWidth
          margin="dense"
          value={editedData.chassisNo}
          onChange={handleChange}
        />
        {/* <TextField
          label="Notes"
          name="notes"
          fullWidth
          margin="dense"
          value={editedData.notes}
          onChange={handleChange}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleSave(editedData)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProdLineModal;
